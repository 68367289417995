body,
h1,
h2,
h3,
h4,
h5,
p div span,
.news-holder ul,
.partners h3,
#footer {
	font-family: "Roboto", sans-serif;
	letter-spacing: 0px !important;
}

body {
	background: none;
	color: #1f1f1f;
}

a {
	color: #00bad4;
}

#AppBody p {
	margin-bottom: 20px !important;
}

#header,
#main,
#footer {
	box-shadow: none !important;
}

body:not(.news) #main {
	padding-top: 40px;
}

div.home #main {
	padding-top: 0;
}

#header {
	padding: 0;
	height: 105px;
}

#header .logo {
	padding-top: 25px;
}

#header .logo img,
div.home .column.yellow a img,
div.home .column.blue a img {
	display: none;
}

#header .logo a {
	background-size: 250px 63px !important;
	float: left;
	height: 63px !important;
	width: 250px;
}

body.geo #header .logo a {
	background: url(../images/logos/H_IAN_Color.png) no-repeat;
}

body.nonprofit #header .logo a {
	background: url(../images/logos/H_IAN_Color.png) no-repeat;
}

body.probono #header .logo a {
	background: url(../images/logos/H_IAN_Color.png) no-repeat;
}

#header .logo::after,
#header .logo::before {
	display: none;
}

#header .links-holder {
	width: auto;
}

#header ul.lang {
	padding: 0;
}

#nav {
	margin-top: 50px;
}

#nav ul li {
	//margin-left: 35px;
}

#nav ul li a {
	//font-family: "Montserrat";
	text-transform: none;
	font-weight: bold;
	//color: #f6871f;
	color: $martinique;
	font-size: 14px;
}

#nav ul li:last-child a {
	//color: #00d4ad;
	color: $international-orange;
}

#nav ul li:after {
	display: none;
}

.visual,
.visual > img {
	height: 367px;
}

div.home .visual > img,
.powered,
.created {
	display: none;
}

div:not(.home) .visual {
	height: 357px;
}

div:not(.home) .visual > img {
	height: 370px;
}

.visual .caption {
	border: none;
	width: 385px;
	padding: 45px 40px 0 65px;
}

body.geo .visual .caption {
	//background: rgba(12, 12, 12, 0.85);
	background: $martinique;
}

body.nonprofit .visual .caption {
	//background: rgba(0, 212, 173, 0.95);
	background: $martinique;
}

body.probono .visual .caption {
	//background: rgba(0, 186, 212, 0.9);
	background: $martinique;
}

div:not(.home) .visual .caption {
	width: 345px;
	padding: 50px 30px 0 35px;
}

body.geo div:not(.home) .visual .caption {
	background: rgba(246, 135, 31, 0.9);
}

body.geo div:not(.home) .visual .caption:after {
	border-right-color: rgba(246, 135, 31, 0.9);
}

body.nonprofit div:not(.home) .visual .caption {
	background: rgba(246, 135, 31, 0.9);
}

body.probono div:not(.home) .visual .caption {
	background: rgba(246, 135, 31, 0.9);
}

.visual .caption:after {
	height: 367px;
	border-width: 0 70px 367px 0;
	left: -70px;
}

body.geo .visual .caption:after {
	//border-right-color: rgba(12, 12, 12, 0.85);
	border-right-color: $martinique;
}

body.nonprofit .visual .caption:after {
	//border-right-color: rgba(0, 212, 173, 0.95);
	border-right-color: $martinique;
}

body.probono .visual .caption:after {
	//border-right-color: rgba(0, 186, 212, 0.9);
	border-right-color: $martinique;
}

body.geo.cw-project div:not(.home) .visual .caption {
	background: rgba(152, 194, 85, 0.9);
}

body.geo.immi-project div:not(.home) .visual .caption {
	background: rgba(155, 196, 101, 0.9);
}

body.geo.swi-project div:not(.home) .visual .caption {
	background: rgba(241, 196, 15, 0.9);
}

body.geo.oportunidad-project div:not(.home) .visual .caption {
	background: rgba(176, 42, 62, 0.9);
}

body.geo.stepforward-project div:not(.home) .visual .caption {
	background: rgba(74, 66, 64, 0.8);
}

body.geo.immigo-project div:not(.home) .visual .caption {
	background: rgba(208, 55, 50, 0.85);
}

body.geo.cw-project div:not(.home) .visual .caption:after,
body.geo.immi-project div:not(.home) .visual .caption:after,
body.geo.swi-project div:not(.home) .visual .caption:after,
body.geo.oportunidad-project div:not(.home) .visual .caption:after,
body.geo.stepforward-project div:not(.home) .visual .caption:after,
body.geo.immigo-project div:not(.home) .visual .caption:after {
	display: initial;
}

body.geo.cw-project div:not(.home) .visual .caption:after {
	border-right-color: rgba(152, 194, 85, 0.9);
}

body.geo.immi-project div:not(.home) .visual .caption:after {
	border-right-color: rgba(155, 196, 101, 0.9);
}

body.geo.swi-project div:not(.home) .visual .caption:after {
	border-right-color: rgba(241, 196, 15, 0.9);
}

body.geo.oportunidad-project div:not(.home) .visual .caption:after {
	border-right-color: rgba(176, 42, 62, 0.9);
}

body.geo.stepforward-project div:not(.home) .visual .caption:after {
	border-right-color: rgba(74, 66, 64, 0.8);
}

body.geo.immigo-project div:not(.home) .visual .caption:after {
	border-right-color: rgba(208, 55, 50, 0.85);
}

body.geo.cw-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.immi-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.swi-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.oportunidad-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.stepforward-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.immigo-project div#wrapper:not(.home) .visual .caption a.more {
	background: #f2f2f2 !important;
	color: #1f1f1f !important;
}

body.geo.cw-project div#wrapper:not(.home) .visual .caption a.more {
	width: 200px;
}

body.geo.swi-project div#wrapper:not(.home) .visual .caption a.more {
	width: 200px;
	padding-bottom: 55px;
}

body.geo.stepforward-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.oportunidad-project div#wrapper:not(.home) .visual .caption a.more,
body.geo.immigo-project div#wrapper:not(.home) .visual .caption a.more {
	width: 250px;
}

body.geo.cw-project div#wrapper:not(.home) .visual .caption a.more:hover,
body.geo.immi-project div#wrapper:not(.home) .visual .caption a.more:hover,
body.geo.swi-project div#wrapper:not(.home) .visual .caption a.more:hover,
body.geo.oportunidad-project
	div#wrapper:not(.home)
	.visual
	.caption
	a.more:hover,
body.geo.stepforward-project
	div#wrapper:not(.home)
	.visual
	.caption
	a.more:hover,
body.geo.immigo-project div#wrapper:not(.home) .visual .caption a.more:hover {
	background: #ccc !important;
}

body.geo.swi-project div:not(.home) .visual .caption #overview {
	margin-left: -20px;
}

body.geo.immigo-project div:not(.home) .visual .caption #overview h1,
body.geo.immigo-project div:not(.home) .visual .caption #overview p {
	color: #fff;
}

body.geo.oportunidad-project div:not(.home) .visual img {
	margin-left: -90px;
	padding-top: 5px;
}

body.projects #subheader_copy p {
	text-align: center;
}

body.projects #subheader_copy p img {
	width: 80%;
	margin-left: 0;
}

.visual h1,
.visual p {
	text-transform: none;
	line-height: normal;
}

.visual h1 {
	color: #fff;
	font-weight: bold;
	font-size: 30px;
	margin-bottom: 20px;
}

div:not(.home) #content .visual .caption h1 {
	font-size: 30px;
}

.visual p {
	font-size: 20px;
	margin-bottom: 30px;
}

body.geo .visual p {
	color: #c4c4c4;
}

body.geo div.home .visual p {
	font-size: 18px;
}

body.geo div.home .visual .caption {
	padding-top: 35px;
}

body.nonprofit .visual p,
body.probono .visual p {
	color: #fff;
}

body.nonprofit .visual .caption a.more,
body.probono .visual .caption a.more {
	background: $international-orange;
	color: #fff;
}

body.nonprofit .visual .caption a.more:hover,
body.probono .visual .caption a.more:hover {
	background: #f57c0a;
}

div:not(.home) .visual p {
	color: #fff;
}

.visual .more {
	width: 150px;
	height: 38px;
	padding-top: 6px;
	font-size: 16px;
	font-weight: bold;
	border: none;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
}

div:not(.home) #content {
	overflow: visible !important;
}

div:not(.home) .visual {
	margin-left: -24px;
	margin-top: -50px;
}

div:not(.home) .visual h1 {
	font-style: normal !important;
}

.heading p {
	text-transform: none;
	font-family: "Roboto", sans serif;
	font-size: 15px;
}

.partners {
	float: left;
}

.column-holder {
	margin-left: 0;
	margin-right: 0;
}

.column-holder h2 {
	text-transform: none;
	font-weight: bold;
	font-size: 20px;
}

.column-holder h2 a,
.column-holder a {
	color: #fff;
}

.column-holder h2 a:hover,
.column-holder a:hover {
	color: #fff;
	text-decoration: none;
}

.column-holder p {
	font-size: 14px;
	color: #fff;
}

.column-holder .column:hover {
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
}

.column-holder .column.yellow {
	//background: #00d4ad;
	background: $martinique;
}

.column-holder .column.blue {
	//background: #00bad4;
	background: $martinique;
}

.column-holder .column.green {
	//background: #f6a01f;
	background: $international-orange;
}

.column-holder .column.yellow,
.column-holder .column.blue,
.column-holder .column.green {
	//width: 316px;
	width: 315px;
	height: 345px;
	margin: 0;
	border-bottom: none;
}

.column-holder .column.yellow {
	margin-right: 11px;
}

.column-holder .column.blue {
	margin-left: 7px;
	margin-right: 7px;
}

.column-holder .column.green {
	margin-left: 11px;
	text-align: center;
}

.column-holder .column.green #right-column {
	padding-top: 60px;
	height: 345px;
}

.column-holder .column.green a,
.column-holder .column.green h2 {
	padding: 0;
}

.column-holder .column.green h2 {
	margin: 18px 0;
}

.column-holder .column.green p#find-icon {
	text-align: center;
}

.column-holder .column.green p#find-icon img {
	width: 84px;
	height: 84px;
	margin-left: calc(50% - 42px);
}

.column-holder .column > a > img {
	width: 100%;
	height: 198px;
	margin-bottom: 0;
}

.column-holder .column.green > a > img {
	display: none;
}

.column-holder .column #right-column {
	padding: 0 40px;
}

.column-holder .column #left-column,
.column-holder .column #middle-column {
	padding-top: 30px;
	padding-left: 35px;
	height: 147px;
}

.column-holder .column #left-column div:before,
.column-holder .column #middle-column div:before {
	position: absolute;
	content: "";
	height: 88px;
	width: 6px;
	background: $international-orange;
	margin-left: -20px;
}

.news-holder {
	margin-left: 0;
	margin-right: 0;
}

.news-holder .news {
	//width: 316px;
	width: 315px;
}

.news-holder .news#news-leadin {
	margin-left: 0;
	margin-right: 11px;
}

.news-holder .news#events-leadin {
	margin-left: 7px;
	margin-right: 7px;
}

.news-holder .news#links-leadin {
	margin-left: 11px;
	margin-right: 0;
}

.news-holder h3 {
	text-transform: none;
	font-weight: bold;
	font-size: 14px;
	color: #020202;
}

.news-holder ul li {
	padding-left: 0 !important;
	border-color: $international-orange;
	color: #6d6d6d;
}

.news-holder ul li:after {
	display: none;
}

.news-holder ul time,
.news-holder ul em {
	display: inline;
}

.news-holder ul li em {
	font-size: 13px;
	font-weight: normal;
	color: #6d6d6d;
}

.news-holder ul li em:before {
	content: "(";
}

.news-holder ul li em:after {
	content: ")";
}

.news-holder ul li a {
	color: #6d6d6d;
}

#partners h3 {
	text-transform: none;
	font-weight: bold;
	font-size: 14px;
}

#partners h3:before,
#partners h3:after {
	//background: #f6d39f;
	background: $international-orange;
}

.partners ul {
	width: 100%;
	margin: 0px !important;
	padding: 0 !important;
	text-align: center;
}

.partners ul li {
	display: inline-table;
	width: 19.6%;
	margin: 0 !important;
	padding: 0 !important;
}

.partners ul li .img-holder {
	height: 150px;
	padding: 0 !important;
	margin: 0 !important;
	width: 20%;
	text-align: center !important;
}

.partners ul li .img-holder img {
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
	max-width: 150px;
	height: auto !important;
}

.partners > div:not(#featured_on) ul li:last-child {
	margin-left: 15px !important;
	margin-right: -60px !important;
}

.partners > div:not(#featured_on) ul li:last-child img {
	max-width: 230px !important;
	width: 230px !important;
}

#footer {
	background: rgba(246, 160, 31, 0.07);
	border: none;
	height: 200px;
}

#footer p,
#footer p a {
	color: #6d6d6d;
}

#footer p#contact a {
	color: #00bad4;
}

#footer .copyright,
#footer p#powered-by {
	float: left;
	clear: none;
}

#footer .copyright {
	width: 33%;
}

#footer p#powered-by {
	margin-top: 15px;
}

#footer p#powered-by {
	width: 100%;
	text-align: left;
	margin: 12px 0 20px;
}

#footer p#powered-by img {
	height: 18px;
}

#footer ul li img {
	width: 35px;
	height: 35px;
}

#footer ul li img:hover {
	opacity: 0.8;
}

#footer ul {
	float: right;
	margin-top: 5px;
}

#footer ul li {
	list-style: none;
	float: left;
	margin-left: 8px;
}

#vr-signup div {
	width: 33%;
	float: left;
	margin-top: 10px;
	font-size: 14px;
	text-align: center;
}

#vr-signup span {
	color: #f6871f;
	font-size: 15px;
}

#vr-signup input[name="email_address"] {
	margin: 5px 5px 0 0;
}

#vr-signup input[type="submit"] {
	border-radius: 4px !important;
	border: 1px solid #ccc;
	padding: 3px 5px;
	margin-top: 5px;
}

#vr-signup input[type="submit"]:hover {
	background: #ccc;
}

.inner .logos-box .logo:before,
.inner .logos-box .logo:after {
	display: none;
}

body.inner p a,
div.pagnation a {
	color: #00bad4;
}

div.pagnation a {
	background: none !important;
}

div.pagnation a.next:after {
	content: " >";
}

div.pagnation a.prev:before {
	content: "< ";
}

.inner .content-summary.flx,
.inner .content-summary.resources {
	font-size: 14px !important;
	color: #1f1f1f !important;
	margin-left: 15px !important;
	border-top: 1px solid #dadada;
}

.inner .content-summary.flx {
	margin-left: 0 !important;
}

.alerts .leadin p {
	margin-top: 30px !important;
	margin-left: 1em !important;
}

.jobs div#AppMain #AppBody .leadin p {
	margin-bottom: 0 !important;
}

.inner #content h2 {
	margin-bottom: 20px;
}

.members a {
	color: #00bad4;
}

.volunteer #main,
.legaldirectory #main {
	padding-top: 0 !important;
}

.volunteer #directory-header-bar,
.legaldirectory #directory-header-bar {
	float: left;
	width: 100%;
	margin: 30px 0;
}

.volunteer #directory-header-bar #directory-header-breadcrumb,
.legaldirectory #directory-header-bar #directory-header-breadcrumb {
	float: left;
}

.volunteer #directory-header-bar #directory-header-tools,
.volunteer #directory-language-form,
.legaldirectory #directory-header-bar #directory-header-tools,
.legaldirectory #directory-language-form {
	float: right !important;
}

.volunteer #directory-search-tabs,
.volunteer #directory-search-results,
.legaldirectory #directory-search-tabs,
.legaldirectory #directory-search-results {
	float: left !important;
	clear: left !important;
	width: 100%;
}

.volunteer #organization-detail-name h3,
.legaldirectory #organization-detail-name h3 {
	line-height: 28px;
}

body.geo.legaldirectory #organization-detail-name h3 {
	color: #f6871f;
}

body.nonprofit.legaldirectory #organization-detail-name h3 {
	color: #00d4ad !important;
}

body.probono.volunteer #organization-detail-name h3 {
	color: #00bad4;
}

body.nonprofit.legaldirectory .directory-organization-summary a,
body.probono.volunteer .directory-organization-summary a {
	color: #00bad4 !important;
}

.volunteer .directory-organization-summary h4 a,
.legaldirectory .directory-organization-summary h4 a {
	line-height: 26px !important;
}

.volunteer div.organization-detail-action a,
.legaldirectory div.organization-detail-action a {
	line-height: 23px !important;
}

body.geo.legaldirectory div.organization-detail-action,
body.geo.legaldirectory div.organization-detail-action a:hover {
	background-color: #fde5ce !important;
}

body.nonprofit.legaldirectory div.organization-detail-action,
body.nonprofit.legaldirectory div.organization-detail-action a:hover {
	background-color: #ccfff6 !important;
}

body.probono.volunteer div.organization-detail-action,
body.probono.volunteer div.organization-detail-action a:hover {
	background-color: #ccf9ff !important;
}

.volunteer #directory-header-breadcrumb a,
.volunteer .organization-detail-props a,
.legaldirectory #directory-header-breadcrumb a,
.legaldirectory .organization-detail-props a {
	color: #00bad4 !important;
}

.volunteer div#organization-detail-name,
.legaldirectory div#organization-detail-name {
	background: none;
	margin-top: 0 !important;
}

.volunteer #directory-organization-details,
.legaldirectory #directory-organization-details {
	margin-bottom: 40px;
}

/* Practice area home and flex page grids */
div#content {
	margin: 0 !important;
}

div#content .visual {
	margin-left: 0;
}

#toolicons,
#staffpics {
	width: 100%;
	text-align: center;
}

#toolicons p,
#staffpics p {
	color: #6d6d6d;
	font-size: 14px;
	line-height: normal;
}

p#intro {
	text-align: center;
	line-height: 20px;
	margin: 0 auto 50px;
	width: 90%;
}

body.geo p#intro a {
	color: #00bad4;
}

#toolicons .box,
#staffpics .box {
	float: left;
	border-radius: 4px;
	overflow: hidden;
	width: 314px;
	margin-bottom: 22px;
	box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.13);
	border: 1px solid transparent;
}

#toolicons .box {
	height: 330px;
}

#staffpics .box {
	min-height: 720px;
}

#toolicons .box.left,
#staffpics .box.left {
	margin-right: 11px;
	margin-left: 3px;
}

#toolicons .box.middle,
#staffpics .box.middle {
	margin-left: 11px;
	margin-right: 11px;
}

#toolicons .box.right,
#staffpics .box.right {
	margin-left: 11px;
}

#toolicons .icon,
#staffpics .icon {
	padding-top: 50px;
	text-align: center;
	width: 100%;
	height: 217px;
}

#staffpics .icon {
	height: 300px;
	padding-top: 20px;
}

#toolicons .box img {
	height: 50%;
	width: auto;
	max-width: 90%;
}

#toolicons .box img#cw-logo {
	height: auto;
	padding-top: 30px;
}

#staffpics .box img {
	max-height: 260px;
	max-width: 280px;
	margin: 0;
}

#toolicons .text,
#staffpics .text {
	overflow: hidden;
	text-transform: none;
	width: 100%;
	padding: 0 20px;
	text-align: left;
}

#toolicons .text {
	height: 113px;
}

#staffpics .text {
	min-height: 375px;
}

#toolicons .text:before,
#staffpics .text:before {
	position: absolute;
	content: "";
	width: 5px;
	height: 77px;
	margin-left: -22px;
}

body.geo #toolicons .text:before,
body.geo #staffpics .text:before {
	background: #f6a01f;
}

body.nonprofit #toolicons .text:before {
	background: #f6a01f;
}

body.probono #toolicons .text:before {
	background: #f6a01f;
}

#toolicons h2,
#staffpics h2 {
	color: #000;
	margin: 0 0 6px;
	text-transform: capitalize;
	font-size: 20px;
	font-weight: bold;
	line-height: 23px;
}

body.nonprofit #toolicons h2,
body.probono #toolicons h2 {
	font-size: 17px;
}

#staffpics h2 {
	text-transform: uppercase;
	margin-bottom: 0;
}

#toolicons h2 a,
#staffpics h2 a {
	color: #000;
}

#toolicons h3,
#staffpics h3 {
	position: relative;
	display: inline-block;
	vertical-align: top;
	text-transform: capitalize;
	padding: 0 39px;
	font-weight: bold;
	font-size: 20px !important;
	color: #000;
	text-align: center;
	margin: 50px 0 30px;
}

#toolicons h3:before,
#toolicons h3:after,
#staffpics h3:before,
#staffpics h3:after {
	background: #f6a01f;
	content: "";
	height: 1px;
	left: 100%;
	position: absolute;
	top: 7px;
	width: 9999px;
}

#toolicons h3:before,
#staffpics h3:before {
	left: auto;
	right: 100%;
}

#staffpics p.title {
	color: #f6a01f;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 10px;
}

body.nonprofit #toolicons .box,
body.probono #toolicons .box {
	height: 230px;
}

body.nonprofit #toolicons .icon,
body.probono #toolicons .icon {
	height: 110px;
	padding-top: 30px;
}

body.nonprofit #toolicons .icon img,
body.probono #toolicons .icon img {
	height: 55px;
}

body.nonprofit #toolicons .text,
body.probono #toolicons .text {
	height: 120px;
	padding-bottom: 40px;
	text-align: center;
	padding-left: 25px;
	padding-right: 25px;
}

body.nonprofit #toolicons .text:before,
body.probono #toolicons .text:before {
	background: none;
}

body.nonprofit #toolicons .text:after,
body.probono #toolicons .text:after {
	content: "learn more";
	text-transform: uppercase;
	font-family: "Roboto", sans serif;
	font-size: 13px;
	font-weight: bold;
	display: inline-block;
	margin-top: 20px;
}

body.nonprofit #toolicons .text:after {
	color: #00d4ad;
}

body.probono #toolicons .text:after {
	color: #00bad4;
}

body.geo #toolicons .box:hover {
	box-shadow: 0px 0px 8px #f6a01f8a;
	border: 1px solid #f6a01f8a;
}

body.nonprofit #toolicons .box:hover {
	box-shadow: 0px 0px 8px #00d4ad8a;
	border: 1px solid #00d4ad8a;
}

body.probono #toolicons .box:hover {
	box-shadow: 0px 0px 8px #00bad48a;
	border: 1px solid #00bad48a;
}

#toolicons .box a:hover,
#staffpics .box a:hover {
	text-decoration: none;
}

#subheader_copy h3 {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 20px;
	text-transform: none;
}

.award {
	float: left;
	width: 80%;
	margin: 0 10% 10px;
}

.award div {
	float: left;
	width: 33%;
	height: 150px;
	text-align: center;
}

.award div img {
	max-width: 100%;
	max-height: 100%;
	margin: 0;
}

.award div:first-child img {
	width: 80%;
	padding-top: 35px;
}
.award div:last-child img {
	width: 70%;
	padding-top: 35px;
}

form.main fieldset {
	background-color: #fff;
}

@media (max-width: 1055px) {
	.column-holder .column.yellow,
	.column-holder .column.green,
	.column-holder .column.blue,
	.news-holder .news {
		width: calc(33% - 3px);
	}

	.column-holder .column.yellow,
	.news-holder .news#news-leadin {
		margin-right: 0px;
	}

	.column-holder .column.blue,
	.news-holder .news#events-leadin {
		margin-left: 3px;
		margin-right: 3px;
	}

	.column-holder .column.green,
	.news-holder .news#links-leadin {
		margin-left: 0px;
	}

	#toolicons .box.left,
	#toolicons .box.middle,
	#toolicons .box.right,
	#staffpics .box.left,
	#staffpics .box.middle,
	#staffpics .box.right {
		width: calc(33% - 26px);
		margin-right: 13px;
		margin-left: 13px;
	}

	#staffpics .box {
		min-height: 750px;
	}

	#toolicons .box img,
	#staffpics .box img {
		max-width: 240px;
	}
}

@media (max-width: 999px) {
	.column-holder .column.yellow,
	.column-holder .column.green,
	.column-holder .column.blue,
	.news-holder .news {
		width: 300px;
		margin: 10px;
	}

	.column-holder {
		text-align: center;
		margin: 0 12px 15px;
	}

	.column-holder .column.green .text {
		text-align: center;
	}

	#toolicons .box.left,
	#toolicons .box.middle,
	#toolicons .box.right,
	#staffpics .box.left,
	#staffpics .box.middle,
	#staffpics .box.right {
		width: calc(50% - 26px);
	}

	#toolicons .box img#cw-logo {
		max-width: 280px;
	}

	#staffpics .box {
		min-height: 685px;
	}

	#staffpics .text {
		min-height: 1px;
	}

	#footer {
		height: 130px;
	}
}

@media (max-width: 940px) {
	.partners ul li {
		width: 30%;
	}

	.partners > div:not(#featured_on) ul li:last-child {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.volunteer #organization-detail-contact td,
	.legaldirectory #organization-detail-contact td {
		float: left;
		width: 100%;
	}

	.volunteer #directory-home-summary,
	.legaldirectory #directory-home-summary {
		display: none;
	}

	.volunteer #directory-home-form-zipcode,
	.volunteer #directory-home-form-detention,
	.volunteer #directory-home-form-detention td,
	.legaldirectory #directory-home-form-zipcode,
	.legaldirectory #directory-home-form-detention,
	.legaldirectory #directory-home-form-detention td {
		width: 100%;
		float: left;
	}

	.volunteer #directory-home-form table,
	.legaldirectory #directory-home-form table {
		margin-left: 0;
	}

	.volunteer #directory-home-form-divider,
	.legaldirectory #directory-home-form-divider {
		display: none;
	}

	#nav ul li {
		margin-left: 16px;
	}
}

@media (max-width: 767px) {
	.visual .caption:after,
	div:not(.home) .visual .caption:after {
		background: none;
	}

	.visual .caption,
	div:not(.home) .visual .caption {
		position: static;
		top: 0;
		right: 0;
		width: 100%;
		padding: 20px 15px 10px;
		border: 0;
	}

	.visual,
	div:not(.home) .visual {
		height: auto;
	}

	.visual img,
	div:not(.home) .visual img {
		display: block;
		width: 125%;
		height: auto;
	}

	.visual .thickbox img,
	div:not(.home) .visual .thickbox img {
		display: none;
	}

	.visual p:last-child {
		text-align: center;
	}

	body.geo div.home .visual img,
	body.nonprofit div.home .visual img {
		margin-left: -270px;
	}

	#nav {
		margin-top: 0;
	}

	#nav a.opener {
		margin-right: 0;
	}

	#nav ul {
		background: rgba(0, 0, 0, 0.8);
	}

	#nav ul li {
		margin-left: 0;
	}

	#nav ul li a:hover {
		background: #f6a01f;
		color: #000 !important;
	}

	/*#footer {
    padding-top: 10px;
    height: 130px;
  }*/

	#footer .copyright {
		width: 50%;
		text-align: left;
		margin-top: 0;
		float: left;
	}

	/*#footer p#powered-by {
    width: 40%;
  }*/

	#vr-signup div {
		width: 50%;
		text-align: right;
		margin: 0 0 15px;
	}

	.award {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	body.nonprofit div.home #toolicons .text,
	body.probono div.home #toolicons .text {
		padding: 0 10px;
	}

	body.nonprofit div.home #toolicons .text p,
	body.probono div.home #toolicons .text p {
		font-size: 13px;
	}

	#staffpics .box {
		min-height: 720px;
	}

	body.geo .visual .caption {
		background: rgb(12, 12, 12);
	}

	body.nonprofit .visual .caption {
		background: rgb(0, 212, 173);
	}

	body.probono .visual .caption {
		background: rgb(0, 186, 212);
	}

	body.geo div:not(.home) .visual .caption {
		background: rgb(246, 135, 31);
	}

	body.nonprofit div:not(.home) .visual .caption {
		background: rgb(246, 135, 31);
	}

	body.probono div:not(.home) .visual .caption {
		background: rgb(246, 135, 31);
	}

	body.geo.swi-project div:not(.home) .visual .caption #overview {
		margin-left: 0px;
	}

	body.geo.swi-project div:not(.home) .visual .caption h1 {
		font-size: 26px !important;
	}

	body.geo.oportunidad-project div:not(.home) .visual img {
		margin-left: 0px;
	}

	body.geo.cw-project div:not(.home) .visual .caption {
		background: rgba(152, 194, 85);
	}

	body.geo.immi-project div:not(.home) .visual .caption {
		background: rgba(155, 196, 101);
	}

	body.geo.swi-project div:not(.home) .visual .caption {
		background: rgba(241, 196, 15);
	}

	body.geo.oportunidad-project div:not(.home) .visual .caption {
		background: rgba(176, 42, 62);
	}

	body.geo.stepforward-project div:not(.home) .visual .caption {
		background: rgba(74, 66, 64);
	}

	body.geo.immigo-project div:not(.home) .visual .caption {
		background: rgba(208, 55, 50);
	}

	body.geo.immigo-project div:not(.home) .visual img {
		width: 100% !important;
	}

	.partners > div:not(#featured_on) ul li:last-child {
		margin-left: 0px !important;
		width: 180px;
	}

	.partners > div:not(#featured_on) ul li:nth-last-child(2) img {
		max-width: 150px !important;
	}
	.partners > div:not(#featured_on) ul li:last-child img {
		max-width: 180px !important;
	}
}

@media (max-width: 710px) {
	.column-holder .column.yellow,
	.column-holder .column.green,
	.column-holder .column.blue,
	.news-holder .news {
		margin: 10px 0;
	}

	.column-holder {
		text-align: center;
		margin: 0;
	}

	#toolicons .box.left,
	#toolicons .box.middle,
	#toolicons .box.right,
	#staffpics .box.left,
	#staffpics .box.middle,
	#staffpics .box.right {
		width: calc(100% - 26px);
	}

	#toolicons .box {
		height: 270px;
	}

	#toolicons .icon {
		height: 180px;
		padding-top: 30px;
	}

	#toolicons .text {
		height: 90px;
	}

	#toolicons .box img#cw-logo {
		max-width: 250px;
	}

	body.geo #toolicons .text {
		margin-top: -30px;
	}

	#staffpics .box {
		min-height: 1px;
	}

	body.nonprofit #heading,
	body.probono #heading {
		margin: 0;
	}
}

@media (max-width: 600px) {
	p#intro {
		margin-bottom: 30px;
	}
	.award,
	.award div {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.award div {
		height: 100px;
	}

	.award div:first-child {
		height: 70px;
	}

	.award div:nth-child(2) {
		height: 150px;
	}

	.award div:first-child img {
		width: 175px;
		padding-top: 0;
	}

	.award div:nth-child(2) img {
		padding-top: 20px;
	}

	.award div:last-child img {
		padding-top: 30px;
		width: 150px;
	}

	/*  .partners ul {
    margin-left: 30px !important;
  }*/

	.partners ul li,
	.partners > div:not(#featured_on) ul li:last-child,
	.partners > div:not(#featured_on) ul li:nth-last-child(2) {
		width: 45%;
	}

	.partners ul li .img-holder {
		height: 100px;
	}

	#footer .copyright {
		width: 100%;
	}

	#vr-signup div,
	#footer ul {
		width: 100%;
		text-align: center !important;
	}

	#footer ul {
		margin-left: calc(50% - 86px);
		float: left;
	}

	#footer {
		height: 280px;
		padding-left: 0;
		padding-right: 0;
	}

	#footer .copyright p,
	#footer p#powered-by {
		text-align: center;
	}

	#footer p#powered-by {
		width: 100%;
	}

	#footer .copyright p:last-child {
		width: 98%;
	}

	#vr-signup div {
		line-height: 30px;
	}

	#vr-signup span {
		width: 100%;
		float: left;
	}

	.members div.member-list dl {
		width: 100%;
	}

	body.legaldirectory:not(.calendar):not(.library):not(.news)
		h2.tool::before {
		top: 45px !important;
	}

	body.volunteer div.directory-search-form-bottom-field label.field-header,
	body.legaldirectory
		div.directory-search-form-bottom-field
		label.field-header {
		font-size: 13px;
	}

	body.volunteer div.directory-search-form-bottom-field .checkbox,
	body.legaldirectory div.directory-search-form-bottom-field .checkbox {
		display: table !important;
		width: 20px !important;
		float: left;
	}

	body.volunteer div.directory-search-form-bottom-field .label,
	body.legaldirectory div.directory-search-form-bottom-field .label {
		display: table !important;
		width: calc(100% - 20px) !important;
		font-size: 13px;
	}

	body.volunteer div.directory-search-form-bottom-field select,
	body.legaldirectory div.directory-search-form-bottom-field select {
		font-size: 13px;
	}

	body.volunteer div.directory-search-form-bottom-field td:not(.checkbox),
	body.legaldirectory
		div.directory-search-form-bottom-field
		td:not(.checkbox) {
		margin-top: 1px !important;
	}

	.volunteer .organization-detail-section table td,
	.legaldirectory .organization-detail-section table td {
		width: 100%;
		float: left;
	}
}

@media (max-width: 440px) {
	body.volunteer
		div#directory-home-form
		div#directory-home-form-county
		table
		tr
		td,
	body.volunteer
		div#directory-home-form
		div#directory-home-form-county
		table
		tr
		td
		select {
		width: 100% !important;
		float: left;
	}
}

@media (max-width: 420px) {
	#header .logo a {
		max-width: 250px;
		background-size: 250px auto !important;
	}

	.partners ul {
		width: 100%;
		margin: 0 !important;
		padding: 0 !important;
	}

	.partners > div:not(#featured_on) ul li:last-child,
	.partners > div:not(#featured_on) ul li:nth-last-child(2),
	.partners ul li,
	.partners ul li .text {
		width: 100%;
	}

	.partners ul li .img-holder {
		height: 90px;
	}

	.partners ul li img {
		margin: 0 !important;
	}

	body.projects #subheader_copy p img {
		width: 100%;
	}
}

/* Tool page re-skinning */

body.geo #header {
	border-bottom-color: #f6871f !important;
}

body.nonprofit #header {
	border-bottom-color: #00d4ad !important;
}

body.probono #header {
	border-bottom-color: #00bad4 !important;
}

body:not(.about):not(.projects) div#wrapper:not(.home) #header {
	border-bottom: 1px solid;
}

.calendar-style-nav a {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 13px;
}

.calendar-style-nav a:before {
	content: "< ";
}

.calendar #m-header .page-tools.hmenu.lower {
	top: 0px !important;
}

#eventAtAGlance {
	margin-top: 10px !important;
	margin-bottom: 25px !important;
}

body.geo .calendar-style-nav a,
body.geo h2#ToolTitle,
body.geo h2.tool,
body.geo h2.tool-title,
body.geo div.content-detail h3.summary {
	color: #f6871f !important;
}

body.nonprofit .calendar-style-nav a,
body.nonprofit h2#ToolTitle,
body.nonprofit h2.tool,
body.nonprofit h2.tool-title,
body.nonprofit div.content-detail h3.summary {
	color: #00d4ad !important;
}

body.probono .calendar-style-nav a,
body.probono h2#ToolTitle,
body.probono h2.tool,
body.probono h2.tool-title,
body.probono div.content-detail h3.summary {
	color: #00bad4 !important;
}

h2#ToolTitle,
h2.tool {
	float: left !important;
	font-size: 26px;
	text-transform: capitalize;
	font-weight: bold;
}

h2.tool {
	margin-left: 54px;
}

.inner h2#ToolTitle {
	margin-left: 0;
}

body.alerts h2#ToolTitle,
body.jobs h2#ToolTitle,
body.legaldirectory h2#ToolTitle,
body.members h2#ToolTitle,
body.podcasts h2#ToolTitle,
body.volunteer h2#ToolTitle,
body.webinars h2#ToolTitle {
	margin-left: 55px;
}

body.geo.calendar h2.tool::before {
	background: url("../images/icons/calendar_icon_orange.png") no-repeat;
}

body.nonprofit.calendar h2.tool::before {
	background: url("../images/icons/calendar_icon_green.png") no-repeat;
}

body.probono.calendar h2.tool::before {
	background: url("../images/icons/calendar_icon_blue.png") no-repeat;
}

body.geo.news h2.tool::before {
	background: url("../images/icons/news_icon_orange.png") no-repeat;
}

body.nonprofit.news h2.tool::before {
	background: url("../images/icons/news_icon_green.png") no-repeat;
}

body.probono.news h2.tool::before {
	background: url("../images/icons/news_icon_blue.png") no-repeat;
}

body.geo.library h2.tool::before {
	background: url("../images/icons/library_icon_orange.png") no-repeat;
}

body.geo.legaldirectory h2.tool:before {
	background: url("../images/icons/legal_directory_icon_orange.png") no-repeat;
}

body.nonprofit.library h2.tool::before {
	background: url("../images/icons/library_icon_green.png") no-repeat;
}

body.probono.library h2.tool::before {
	background: url("../images/icons/library_icon_blue.png") no-repeat;
}

body.nonprofit.alerts h2.tool:before {
	background: url("../images/icons/alerts_icon_green.png") no-repeat;
}

body.nonprofit.jobs h2#ToolTitle:before {
	background: url("../images/icons/jobs_icon_green.png") no-repeat;
}

body.nonprofit.legaldirectory h2.tool:before {
	background: url("../images/icons/legal_directory_icon_green.png") no-repeat;
}

body.nonprofit.members h2#ToolTitle:before {
	background: url("../images/icons/members_icon_green.png") no-repeat;
}

body.nonprofit.podcasts h2#ToolTitle:before {
	background: url("../images/icons/podcasts_icon_green.png") no-repeat;
}

body.nonprofit.volunteer h2#ToolTitle:before {
	background: url("../images/icons/volunteer_guide_icon_blue.png") no-repeat;
}

body.nonprofit.webinars h2#ToolTitle:before {
	background: url("../images/icons/webinars_icon_green.png") no-repeat;
}

body.probono.alerts h2.tool:before {
	background: url("../images/icons/alerts_icon_blue.png") no-repeat;
}

body.probono.jobs h2#ToolTitle:before {
	background: url("../images/icons/jobs_icon_blue.png") no-repeat;
}

body.probono.members h2#ToolTitle:before {
	background: url("../images/icons/members_icon_blue.png") no-repeat;
}

body.probono.podcasts h2#ToolTitle:before {
	background: url("../images/icons/podcasts_icon_blue.png") no-repeat;
}

body.probono.volunteer h2.tool:before {
	background: url("../images/icons/volunteer_guide_icon_blue.png") no-repeat;
}

body.probono.webinars h2#ToolTitle:before {
	background: url("../images/icons/webinars_icon_blue.png") no-repeat;
}

h2#ToolTitle:before,
h2.tool:before {
	position: absolute;
	content: "";
}

h2.tool:before {
	left: 0;
	top: 45px;
}

body:not(.calendar):not(.library):not(.news):not(.volunteer) h2.tool:before {
	left: 0px;
	top: 75px;
}

body.legaldirectory:not(.calendar):not(.library):not(.news):not(.volunteer)
	h2.tool:before {
	top: 35px;
}

body.volunteer h2.tool:before {
	top: 15px;
}

h2#ToolTitle:before {
	left: 0px;
	top: 75px;
}

body:not(.calendar):not(.library):not(.news) h2.tool:before,
h2#ToolTitle:before,
body.calendar h2.tool:before,
body.news h2.tool:before {
	background-size: 42px 42px !important;
	width: 42px;
	height: 42px;
}

body.library h2.tool:before {
	background-size: 38px 53px !important;
	width: 38px;
	height: 53px;
}

.hideInMobile.link {
	text-transform: uppercase;
	font-size: 12px !important;
}

body.geo .hideInMobile.link {
	color: #f6871f !important;
}

body.geo .hideInMobile.link:after {
	color: #f6871f !important;
}

body.nonprofit .hideInMobile.link {
	color: #00d4ad !important;
}

body.nonprofit .hideInMobile.link:after {
	color: #00d4ad !important;
}

body.probono .hideInMobile.link {
	color: #00bad4 !important;
}

body.probono .hideInMobile.link:after {
	color: #00bad4 !important;
}

.tool-options.top.HighlightMid,
.tool-options.bottom.HighlightLight {
	background: #fff;
	box-shadow: 2px 0px 11px rgba(0, 0, 0, 0.13);
	margin-left: 3px !important;
	margin-right: 6px !important;
	border-radius: 4px;
	padding: 15px 20px;
	font-size: 13px;
	text-transform: none;
	font-weight: bold;
	color: #1f1f1f;
}

ul.props,
.description p {
	color: #1f1f1f !important;
}

.library ul.props:not(.attachments) li {
	margin: 20px 0 0 !important;
}

.calendar h3.summary,
.news h3.summary,
.library h3.summary,
.inner h3.summary {
	text-transform: none !important;
}

.calendar .detail-tools div.date {
	font-size: 18px;
}

.alerts .props li.alert-attachment,
.library ul.props.attachments {
	background: #fff;
	box-shadow: 2px 0px 11px rgba(0, 0, 0, 0.13);
	width: calc(100% - 6px) !important;
	margin-left: 3px !important;
	border-radius: 4px;
}

.alerts .props li.alert-attachment {
	padding: 20px !important;
}

.alerts .props li.alert-attachment a {
	color: #00bad4;
}

.alerts .props li.alert-attachment a.PDF::before {
	font-family: "picons-font";
	content: "8";
	margin: 0 7px;
	color: #000;
	font-size: 18px;
}

ul.props.attachments li {
	padding-left: 20px !important;
}

body.geo ul.props.attachments li a,
.topic {
	color: #00bad4;
}

body.nonprofit ul.props.attachments li a,
.topic {
	color: #00bad4;
}

body.probono ul.props.attachments li a,
.topic {
	color: #00bad4;
}

.tool-options.top.HighlightMid span.active {
	background: #f5f5f5;
	padding: 10px;
	border-radius: 8px;
}

.tool-options.top.HighlightMid span.active,
.tool-options.top.HighlightMid a {
	text-transform: uppercase;
	color: #1f1f1f;
}

.tool-options.bottom.HighlightLight {
	border-top: 1px solid #dadada;
	box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.13);
}

.tool-options.bottom.HighlightLight a:first-child {
	margin-left: 25px;
}

.tool-options.bottom.HighlightLight a {
	margin-right: 5px;
	padding-left: 5px !important;
}

body.geo .tool-options.bottom.HighlightLight a {
	color: #00bad4;
}

body.nonprofit .tool-options.bottom.HighlightLight a {
	color: #00bad4;
}

body.probono .tool-options.bottom.HighlightLight a {
	color: #00bad4;
}

div.group h3 {
	color: #000;
	font-weight: bold;
	font-size: 20px;
}

div.group h3:after {
	display: none;
}

div.content-summary h4 a {
	font-weight: normal;
	font-size: 16px;
	color: #000;
}

div.content-summary .props {
	margin: 0 !important;
}

div.content-summary .props li,
div.content-summary .props li a,
div.content-summary .props li strong,
div.content-summary .props li em {
	font-weight: bold;
	font-size: 12px;
	color: #1f1f1f;
}

div#wrapper:not(.home) a.more {
	background: none;
	text-transform: capitalize;
}

body.geo div#wrapper:not(.home) a.more {
	color: #00bad4 !important;
}

body.nonprofit div#wrapper:not(.home) a.more {
	color: #00bad4 !important;
}

body.probono div#wrapper:not(.home) a.more {
	color: #00bad4 !important;
}

div.group {
	border-bottom-color: #f6871f;
}

body.geo .group.othernews a {
	color: #00bad4;
}

body.nonprofit .group.othernews a {
	color: #00bad4;
}

body.probono .group.othernews a {
	color: #00bad4;
}

.news a.return.noprint {
	clear: left;
	margin-top: 20px;
	margin-bottom: 20px;
}

.news #content {
	margin-bottom: 120px !important;
}

/*.breadcrumb:last-child > a,
.oppsguide-page h1.tool, .calendar-page h1.tool,*/
.ct11 .lists li.boolean,
.ct11 .lists li > strong,
.sidebar-list .cases-list li,
.sidebar-list .projects-list li,
.event h4 {
	color: inherit !important;
}

content-display.calendarApp .asCalendar ul .date,
content-display.calendarApp .asCalendar ul .date .date-number {
	color: #000 !important;
	font-size: 30px !important;
	line-height: 40px;
	font-weight: bold;
	padding: 0 !important;
	margin-right: 50px !important;
	border: none;
}

content-display .link {
	color: #00bad4 !important;
}

content-display.calendarApp .asCalendar ul .content-title a {
	color: #000;
	font-size: 20px;
	line-height: 23px;
}

body:not(.library) .content-info:before {
	position: absolute;
	content: "";
	width: 5px;
	height: 77px;
	left: 97px;
}

body.geo:not(.library) .content-info:before {
	background: #f6a01f;
}

body.nonprofit:not(.library) .content-info:before {
	background: #f6a01f;
}

body.probono:not(.library) .content-info:before {
	background: #f6a01f;
}

content-display .list .filters .filter-indicator {
	display: none;
}

content-display .list .hotspot {
	text-transform: uppercase;
	font-size: 12px !important;
	margin-right: 20px;
	color: #1f1f1f !important;
}

.collapsed-folder,
.expanded-folder {
	color: #1f1f1f !important;
}

content-display .list .hotspot::after {
	border-color: #1f1f1f transparent transparent transparent !important;
}

.back,
content-display .list .filters,
content-display .list .filters .rcMenu ul,
content-display.calendarApp .asCalendar ul .date,
.date-box,
.filters::before,
.event h4 {
	background: #fff !important;
}

.filters {
	box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.13);
	width: calc(100% - 6px);
	margin-left: 3px !important;
	border-radius: 4px;
}

content-display .list .filters .rcMenu ul {
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.13) !important;
	border-radius: 4;
}

.folder-stats {
	box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.13);
	padding: 20px;
	width: calc(100% - 6px);
	margin-left: 3px !important;
	border-radius: 4px;
	font-weight: normal !important;
	font-size: 14px !important;
}

.folder-info {
	border-left: 1px solid #dadada;
	padding-left: 40px;
}

div#library-controls.link {
	font-weight: normal;
	font-size: 13px;
}

body.geo div#library-controls.link {
	color: #00bad4 !important;
}

body.nonprofit div#library-controls.link {
	color: #00d4ad !important;
}

body.probono div#library-controls.link {
	color: #00bad4 !important;
}

body.geo div#library-controls.link:after,
body.geo div#library-controls.link.folder-view:after {
	color: #00bad4 !important;
}

body.nonprofit div#library-controls.link:after,
body.nonprofit div#library-controls.link.folder-view:after {
	color: #00d4ad !important;
}

body.probono div#library-controls.link:after,
body.probono div#library-controls.link.folder-view:after {
	color: #00bad4 !important;
}

.library .page-tools.hmenu.lower {
	top: 0px !important;
}

.library .related-content {
	margin-top: -10px !important;
}

.library .content-title.link a {
	color: #000 !important;
	font-weight: normal;
	font-size: 16px;
}

.library-resource-list div,
.library .resource-body div {
	font-weight: bold;
	font-size: 13px;
	color: #1f1f1f !important;
}

body.geo.library .library-resource-list .priority-item div + div a,
body.geo.library .library-resource-list div div:last-child a,
body.geo.library .content-info .resource-body div:last-child a,
body.geo.calendar .rcevent span.link,
body.geo.news #content #m-header a.return,
body.geo.news #content .article a {
	color: #00bad4 !important;
}

.news #content #m-header a.return {
	background: none;
}

.news #content #m-header a.return:before {
	content: "< ";
}

body.nonprofit.library .library-resource-list .priority-item div + div a,
body.nonprofit.library .library-resource-list div div:last-child a,
body.nonprofit.library .content-info .resource-body div:last-child a,
body.nonprofit.calendar .rcevent span.link,
body.nonprofit.news #content #m-header a.return,
body.nonprofit.news #content .article a {
	color: #00bad4 !important;
}

body.probono.library .library-resource-list .priority-item div + div a,
body.probono.library .library-resource-list div div:last-child a,
body.probono.library .content-info .resource-body div:last-child a,
body.probono.calendar .rcevent span.link,
body.probono.news #content #m-header a.return,
body.probono.news #content .article a {
	color: #00bad4 !important;
}

.library #content {
	min-height: 600px;
}

.library #partners {
	margin-top: 50px;
}

.library-resource-list li + li::before {
	height: 1px !important;
	background: #dadada !important;
}

content-display .list li {
	border-color: #dadada !important;
	padding: 10px 0;
}

a.shortcut,
.pageControls .link {
	color: #00bad4 !important;
}

.join-button a:hover {
	background-color: #006be6 !important;
}

.volunteer-request a:hover {
	background-color: #335471 !important;
}

.volunteer-request a,
.join-button a,
content-display .filter-indicator::after,
content-display.calendarApp .asCalendar ul .hasCle,
.clearFloats,
.cleCredit,
content-display.calendarApp .small-calendar .asCalendar .fc-body .rcHasEvent,
content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.fc-other-month,
.ct11 .lists li > div,
.sidebar-list .cases-list .list-title,
.sidebar-list .projects-list .list-title,
content-display .content-alert-message,
.library-resource-list .featured,
content-display .content-alert-message,
.library-resource-list .recent {
	color: #fff;
}

.calendar-page .props > .cleCredit::before {
	border: none;
	background-color: #00bad4;
}

.join-button a {
	border: none;
	background-color: #3994ff;
}

body.geo .volunteer-request a,
body.geo content-display.calendarApp .asCalendar ul .hasCle,
body.geo .clearFloats,
body.geo .cleCredit,
body.geo .ct11 .lists li.boolean.checked,
body.geo .button a {
	background-color: #00bad4 !important;
}

body.nonprofit .volunteer-request a,
body.nonprofit content-display.calendarApp .asCalendar ul .hasCle,
body.nonprofit .clearFloats,
body.nonprofit .cleCredit,
body.nonprofit .ct11 .lists li.boolean.checked,
body.nonprofit .button a {
	background-color: #00bad4 !important;
}

body.probono .volunteer-request a,
body.probono content-display.calendarApp .asCalendar ul .hasCle,
body.probono .clearFloats,
body.probono .cleCredit,
body.probono .ct11 .lists li.boolean.checked,
body.probono .button a {
	background-color: #00bad4 !important;
}

.library-resource-list .featured {
	color: #fff !important;
}

body.geo .props li abbr[title=" Continuing Legal Education"],
body.geo a.email,
body.geo a.external,
body.geo a.external:after,
body.geo .author strong a,
body.geo .back a {
	color: #00bad4 !important;
}

body.nonprofit .props li abbr[title=" Continuing Legal Education"],
body.nonprofit:not(.legaldirectory):not(.volunteer) a.email,
body.nonprofit a.external,
body.nonprofit a.external:after,
body.nonprofit .author strong a,
body.nonprofit .back a {
	color: #00bad4 !important;
}

body.probono .props li abbr[title=" Continuing Legal Education"],
body.probono:not(.legaldirectory):not(.volunteer) a.email,
body.probono a.external,
body.probono a.external:after,
body.probono .author strong a,
body.probono .back a {
	color: #00bad4 !important;
}

a.external {
	background: none;
}

.ct11 .lists li.boolean.checked {
	background: linear-gradient(
		to right,
		#fff 0px,
		#fff 35px,
		#5083b1 35px,
		#5083b1 100%
	) !important; /* W3C */
	background: -moz-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#5083b1 35px,
		#5083b1 100%
	) !important; /* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0px, #fff),
		color-stop(35px, #fff),
		color-stop(35px #5083b1),
		color-stop(100% #5083b1)
	) !important; /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#5083b1 35px,
		#5083b1 100%
	) !important; /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#5083b1 35px,
		#5083b1 100%
	) !important; /* Opera 11.10+ */
	background: -ms-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#5083b1 35px,
		#5083b1 100%
	) !important; /* IE10+ */
}

.ct11 .lists li.boolean.checked::before {
	color: #5083b1 !important;
}

.volunteer-request a {
	border: none;
}

.page-tools.hmenu > li::before,
content-display.calendarApp .small-calendar .asCalendar .fc-body td,
.library-resource-list li > div {
	background-color: #fff;
}

.page-tools.hmenu > li > a,
.page-tools.hmenu > li > a:before,
content-display .showing,
content-display .selected-filter .selected-menu-item {
	color: #7a7870 !important;
}

content-display .list .hotspot::after {
	border-color: inherit transparent transparent transparent !important;
}

content-display .list .filters .rcMenu .rcMenuItem::before {
	border: 1px solid #000;
}

content-display .list .filters .rcMenu.active .hotspot::after {
	border-color: #000 transparent transparent transparent;
}

content-display .list .filters .rcMenu.radio .rcMenuItem::before {
	border: 1px solid #000;
}

body.geo content-display .selected-filter {
	background-color: #fdeace !important;
}

body.nonprofit content-display .selected-filter {
	background-color: #e6fffa !important;
}

body.probono content-display .selected-filter {
	background-color: #e6fcff !important;
}

content-display .selected-filter > span.close {
	color: inherit !important;
}

content-display.calendarApp .asCalendar ul .rcevent,
.list ul li.rc {
	border: none !important;
	box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.13);
	margin: 10px 4px 24px !important;
	padding: 20px !important;
	border-radius: 4;
}

#library-controls:after,
.library-resource-list li > div span.link:hover,
content-display.calendarApp .asCalendar ul .event-info {
	color: #6d6d6d !important;
}

content-display.calendarApp .asCalendar ul .event-info {
	font-size: 14px !important;
}

content-display.calendarApp .small-calendar .hideInMobile.link {
	color: inherit;
}

content-display.calendarApp .small-calendar .date-control {
	border-color: #423e46 !important;
}

content-display.calendarApp .small-calendar .date-control a::after {
	border-color: transparent #000000 transparent transparent;
}

content-display.calendarApp .small-calendar .date-control a.rcnext::after {
	border-color: transparent transparent transparent #423e46;
}

.ui-widget-header {
	border-color: #002b58 !important;
	//background: ##d0d8e0 url("images/ui-bg_highlight-soft_75_cccccc_1x100.png") 50% 50% repeat-x !important;
	color: #423e46 !important;
}

content-display.calendarApp .small-calendar .asCalendar .fc-body td {
	border-color: #1f1f1f !important;
}

body.geo
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasFocus,
body.geo content-display.calendarApp .small-calendar .keyHasFocus::before {
	background-color: #efefef !important;
}

body.geo
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasEvent.rcHasFocus,
body.geo
	content-display.calendarApp
	.small-calendar
	.keyHasEvent.keyHasFocus::before {
	background-color: #f6871f !important;
}

body.geo
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.fc-today,
body.geo
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasFocus.fc-today,
body.geo content-display.calendarApp .small-calendar .keyToday::before {
	background-color: #fdeace !important;
}

body.nonprofit
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasFocus,
body.nonprofit
	content-display.calendarApp
	.small-calendar
	.keyHasFocus::before {
	background-color: #efefef !important;
}

body.nonprofit
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasEvent.rcHasFocus,
body.nonprofit
	content-display.calendarApp
	.small-calendar
	.keyHasEvent.keyHasFocus::before {
	background-color: #00d4ad !important;
}

body.nonprofit
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.fc-today,
body.nonprofit
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasFocus.fc-today,
body.nonprofit content-display.calendarApp .small-calendar .keyToday::before {
	background-color: #e6fffa !important;
}

body.probono
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasFocus,
body.probono content-display.calendarApp .small-calendar .keyHasFocus::before {
	background-color: #efefef !important;
}

body.probono
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasEvent.rcHasFocus,
body.probono
	content-display.calendarApp
	.small-calendar
	.keyHasEvent.keyHasFocus::before {
	background-color: #00bad4 !important;
}

body.probono
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.fc-today,
body.probono
	content-display.calendarApp
	.small-calendar
	.asCalendar
	.fc-body
	.rcHasFocus.fc-today,
body.probono content-display.calendarApp .small-calendar .keyToday::before {
	background-color: #e6fcff !important;
}

content-display .flags .numProjects,
.detail-tools .projects-count,
.sidebar-list .projects-list .list-title {
	background-color: #00808d;
}

content-display .flags .numCases,
.detail-tools .cases-count,
.sidebar-list .cases-list .list-title {
	background-color: #224971;
}

.ct11 .lists li.boolean::before {
	color: #d0d9e1;
}

.ct11 .lists li.boolean.checked::before {
	color: #009148;
}

.ct11 .lists li.boolean {
	background-color: #e9edf1;
	background: linear-gradient(
		to right,
		#fff 0px,
		#fff 35px,
		#e9edf1 35px,
		#e9edf1 100%
	); /* W3C */
	background: -moz-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#e9edf1 35px,
		#e9edf1 100%
	); /* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0px, #fff),
		color-stop(35px, #fff),
		color-stop(35px #e9edf1),
		color-stop(100% #e9edf1)
	); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#e9edf1 35px,
		#e9edf1 100%
	); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#e9edf1 35px,
		#e9edf1 100%
	); /* Opera 11.10+ */
	background: -ms-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#e9edf1 35px,
		#e9edf1 100%
	); /* IE10+ */
}

.ct11 .lists li.boolean.checked {
	background: linear-gradient(
		to right,
		#fff 0px,
		#fff 35px,
		#009148 35px,
		#009148 100%
	); /* W3C */
	background: -moz-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#009148 35px,
		#009148 100%
	); /* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0px, #fff),
		color-stop(35px, #fff),
		color-stop(35px #009148),
		color-stop(100% #009148)
	); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#009148 35px,
		#009148 100%
	); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#009148 35px,
		#009148 100%
	); /* Opera 11.10+ */
	background: -ms-linear-gradient(
		left,
		#fff 0px,
		#fff 35px,
		#009148 35px,
		#009148 100%
	); /* IE10+ */
}

.ct11 .lists li > strong {
	background-color: #e6eaee;
}

div.long-answers {
	background-color: #8095ab;
	background: linear-gradient(
		to right,
		#8095ab 0%,
		#8095ab 35%,
		#fff 35%,
		#fff 35.5%,
		#e6eaee 35.5%,
		#e6eaee 100%
	) !important; /* W3C */
	background: -moz-linear-gradient(
		left,
		#8095ab 0%,
		#8095ab 35%,
		#fff 35%,
		#fff 35.5%,
		#e6eaee 35.5%,
		#e6eaee 100%
	); /* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0%, #8095ab),
		color-stop(35%, #8095ab),
		color-stop(35% #fff),
		color-stop(35.5% #fff),
		color-stop(35.5%, #e6eaee),
		color-stop(100%, #e6eaee)
	); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(
		left,
		#8095ab 0%,
		#8095ab 35%,
		#fff 35%,
		#fff 35.5%,
		#e6eaee 35.5%,
		#e6eaee 100%
	); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(
		left,
		#8095ab 0%,
		#8095ab 35%,
		#fff 35%,
		#fff 35.5%,
		#e6eaee 35.5%,
		#e6eaee 100%
	); /* Opera 11.10+ */
	background: -ms-linear-gradient(
		left,
		#8095ab 0%,
		#8095ab 35%,
		#fff 35%,
		#fff 35.5%,
		#e6eaee 35.5%,
		#e6eaee 100%
	); /* IE10+ */
}

.sidebar-list .cases-list li {
	background-color: #dee4e9;
}

.sidebar-list .projects-list li {
	background-color: #d9ecee;
}

.detail-tools .detail-alert,
content-display .content-alert-message {
	background-color: #eb5557;
}

content-display .content-alert-message {
	border-bottom-color: #fdede5;
}

.content-info.alert-item {
	background-color: #fdeeee !important;
}

.date-box {
	color: #005a3d;
}

body.geo .library-page .props.attachments,
body.geo .library-resource-list .recent + div {
	background-color: rgba(0, 43, 88, 0.07) !important;
}

body.nonprofit .library-page .props.attachments,
body.nonprofit .library-resource-list .recent + div {
	background-color: rgba(0, 43, 88, 0.07) !important;
}

body.probono .library-page .props.attachments,
body.probono .library-resource-list .recent + div {
	background-color: rgba(0, 43, 88, 0.07) !important;
}

.expanded-folder .link,
.selected-folder,
#library-display .folder-breadcrumbs > ul > li > div,
#library-display .folder-info .folder-stats,
#library-display .folder-info .folder-description,
.library-resource-list li > div,
.library-resource-list .priority-item,
.library-resource-list span.icon-file::before,
span.icon-file::before,
.library-resource-list span.icon-url::before,
.expanded-folder.selected-folder .link,
.collapsed-folder.selected-folder .link {
	color: #000 !important;
}

.expanded-folder {
	font-weight: normal !important;
}

.expanded-folder:before {
	color: #1f1f1f !important;
	content: "" !important;
	display: inline-block;
	vertical-align: middle;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 5.5px 0 5.5px;
	border-color: #1f1f1f transparent transparent transparent;
	margin-left: 6px;
	top: 6px !important;
}

.collapsed-folder:before {
	color: #1f1f1f !important;
	content: "" !important;
	display: inline-block;
	vertical-align: middle;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5.5px 0 5.5px 6px;
	border-color: transparent transparent transparent #1f1f1f;
	margin-left: 2px !important;
	top: 2px !important;
}

.selected-folder {
	background: none !important;
	font-weight: normal !important;
}

body.geo .selected-folder {
	color: #f6871f !important;
}

body.nonprofit .selected-folder {
	color: #00d4ad !important;
}

body.probono .selected-folder {
	color: #00bad4 !important;
}

body.geo #library-controls,
body.geo .library-resource-list li > div span.link,
body.geo .library-resource-list .priority-item .link,
body.geo .library-resource-list .resource-title.link {
	color: #3994ff;
}

body.nonprofit #library-controls,
body.nonprofit .library-resource-list li > div span.link,
body.nonprofit .library-resource-list .priority-item .link,
body.nonprofit .library-resource-list .resource-title.link {
	color: #3994ff;
}

body.probono #library-controls,
body.probono .library-resource-list li > div span.link,
body.probono .library-resource-list .priority-item .link,
body.probono .library-resource-list .resource-title.link {
	color: #3994ff;
}

.library-resource-list li + li:before {
	background-color: #ccc !important;
}

.library-resource-list .recent {
	background-color: #002b58 !important;
	color: #fff !important;
}

.library .icon-file:before,
.library .icon-url:before {
	color: #000 !important;
}

.library .resource {
	margin-left: 70px;
	margin-top: 0px !important;
	float: left !important;
	clear: none !important;
}

.library .detail-tools {
	float: left !important;
	clear: none !important;
	margin-bottom: -60px;
	margin-left: -80px;
}

.library .detail-tools a,
.library .detail-tools a:before {
	color: #1f1f1f !important;
}

content-display .content-alert-message {
	border-color: #fdeeee !important;
}

#root-folder .folder-list folderapp > .folder-list:before,
#root-folder .folder-list folderapp > .folder-list .expanded-folder::after,
#root-folder .folder-list folderapp > .folder-list .collapsed-folder::after,
#root-folder .folder-list folderapp > .folder-list folderapp::after {
	background-color: transparent !important;
}

.library-resource-list .priority-item {
	border-color: #000 !important;
}

#library-display > div {
	border-color: #ccc !important;
}

.library .tool-options.accent.spaced a {
	text-transform: none !important;
}

body.geo .library .tool-options.accent.spaced a {
	color: #00bad4 !important;
}

body.nonprofit .library .tool-options.accent.spaced a {
	color: #00bad4 !important;
}

body.probono .library .tool-options.accent.spaced a {
	color: #00bad4 !important;
}

.search-page ul.tabNavigation span.current,
.search-page ul.tabNavigation li a:hover {
	background-color: #31969b;
}

.search-page ul.tabNavigation li a {
	background-color: #e0eff0;
}

.search-page div.search:nth-of-type(2) {
	border-color: #31969b;
}

.is-national:after {
	background-color: #31969b !important;
}

.calendar .back {
	margin-top: 50px !important;
	color: #00bad4;
}

.calendar .page-tools.hmenu {
	top: -60px !important;
}

.calendar #m-header.head-Topic .page-tools.hmenu.lower {
	top: -60px !important;
}

.calendar .head-Date .page-tools.hmenu {
	top: -60px !important;
}

.calendar .head-fullcalendar .page-tools.hmenu {
	top: 0px !important;
}

.calendar .content-detail {
	margin-left: 20px;
	width: 80%;
	float: left !important;
}

.calendar .detail-tools {
	margin-left: 0px;
}

.calendar .detail-tools h4,
.calendar .detail-tools li a,
.calendar .detail-tools li a:before {
	color: #1f1f1f !important;
}

.calendar .detail-tools li.cleCredit {
	color: #fff !important;
}

.calendar #m-header.head-detail,
.library #m-header.head-item {
	height: 150px;
}

body.library .related-content .recent {
	padding: 3px 6px 5px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	font-size: 13px !important;
	line-height: 14px !important;
	text-transform: capitalize !important;
}

body.geo.library .related-content .recent {
	background: #00bad4 !important;
}

body.nonprofit.library .related-content .recent {
	background: #00bad4 !important;
}

body.probono.library .related-content .recent {
	background: #00bad4 !important;
}

body.geo .library-resource-list .recent + div,
body.geo.library .libraryApp .recent + .content-info {
	background-color: #00bad41a !important;
}

body.nonprofit .library-resource-list .recent + div,
body.nonprofit.library .libraryApp .recent + .content-info {
	background-color: #00bad41a !important;
}

body.probono .library-resource-list .recent + div,
body.probono.library .libraryApp .recent + .content-info {
	background-color: #00bad41a !important;
}

content-display .content-alert-message,
.library-resource-list .featured {
	font-size: 13px;
	line-height: 14px;
	font-weight: 700 !important;
	color: #fff;
	text-transform: capitalize !important;
	display: block;
	padding: 3px 6px 5px;
	margin: 0;
}

body.geo content-display .content-alert-message,
body.geo .library-resource-list .featured {
	background: #f6871f !important;
}

body.nonprofit content-display .content-alert-message,
body.nonprofit .library-resource-list .featured {
	background: #f6871f !important;
}

body.probono content-display .content-alert-message,
body.probono .library-resource-list .featured {
	background: #f6871f !important;
}

body.geo.library .library-resource-list .priority-item,
body.geo.library .recent + .content-info.alert-item,
body.geo.library .recent + div.priority-item,
body.geo .content-info.alert-item,
body.geo .libraryApp .content-info > div.resource-body.priority-item,
body.geo .libraryApp .recent + .content-info > div.resource-body.priority-item {
	background: #fef3e8 !important;
}

body.nonprofit.library .library-resource-list .priority-item,
body.nonprofit.library .recent + .content-info.alert-item,
body.nonprofit.library .recent + div.priority-item,
body.nonprofit .content-info.alert-item,
body.nonprofit .libraryApp .content-info > div.resource-body.priority-item,
body.nonprofit
	.libraryApp
	.recent
	+ .content-info
	> div.resource-body.priority-item {
	background: #fef3e8 !important;
}

body.probono.library .library-resource-list .priority-item,
body.probono.library .recent + .content-info.alert-item,
body.probono.library .recent + div.priority-item,
body.probono .content-info.alert-item,
body.probono .libraryApp .content-info > div.resource-body.priority-item,
body.probono
	.libraryApp
	.recent
	+ .content-info
	> div.resource-body.priority-item {
	background: #fef3e8 !important;
}

.library .related-content .rcresource .content-alert-message {
	padding: 2px 6px !important;
}

body.geo.library .related-content .rcresource .content-alert-message {
	background: #f6871f !important;
}

body.nonprofit.library .related-content .rcresource .content-alert-message {
	background: #f6871f !important;
}

body.probono.library .related-content .rcresource .content-alert-message {
	background: #f6871f !important;
}

.library .related-content .rcresource .content-alert-message::before,
.library .related-content .rcresource .content-alert-message::after {
	display: none !important;
}

.calendar .join-button {
	display: none;
}

@media (max-width: 940px) {
	.calendar .event {
		padding-top: 0 !important;
	}
}

@media (max-width: 800px) {
	.collapsed-folder:before,
	.expanded-folder,
	.collapsed-folder,
	.expanded-folder.selected-folder,
	.collapsed-folder.selected-folder {
		color: #000 !important;
	}

	.collapsed-folder:after,
	.selected-folder:after,
	.expanded-folder:after {
		color: #000 !important;
	}

	.expanded-folder.selected-folder::before,
	.collapsed-folder.selected-folder::before,
	.selected-folder:before,
	.expanded-folder:before {
		color: #000;
	}

	#root-folder
		.folder-list
		folderapp
		> .folder-list
		.collapsed-folder::after {
		background-color: transparent !important;
	}

	body.geo .expanded-folder,
	body.geo .collapsed-folder,
	body.geo .expanded-folder.selected-folder,
	body.geo .collapsed-folder.selected-folder {
		background-color: #fef2e7 !important;
	}

	body.nonprofit .expanded-folder,
	body.nonprofit .collapsed-folder,
	body.nonprofit .expanded-folder.selected-folder,
	body.nonprofit .collapsed-folder.selected-folder {
		background-color: #e6fffa !important;
	}

	body.probono .expanded-folder,
	body.probono .collapsed-folder,
	body.probono .expanded-folder.selected-folder,
	body.probono .collapsed-folder.selected-folder {
		background-color: #e6fcff !important;
	}

	body.geo .expanded-folder:hover,
	body.geo .collapsed-folder:hover,
	body.geo .expanded-folder.selected-folder:hover,
	body.geo .collapsed-folder.selected-folder:hover {
		background-color: #fcd8b6 !important;
	}

	body.nonprofit .expanded-folder:hover,
	body.nonprofit .collapsed-folder:hover,
	body.nonprofit .expanded-folder.selected-folder:hover,
	body.nonprofit .collapsed-folder.selected-folder:hover {
		background-color: #b3fff1 !important;
	}

	body.probono .expanded-folder:hover,
	body.probono .collapsed-folder:hover,
	body.probono .expanded-folder.selected-folder:hover,
	body.probono .collapsed-folder.selected-folder:hover {
		background-color: #b3f6ff !important;
	}

	body.geo #root-folder,
	body.geo .expanded-folder,
	body.geo .expanded-folder + div,
	body.geo .collapsed-folder + div,
	body.geo .expanded-folder.selected-folder,
	body.geo .collapsed-folder.selected-folder,
	body.geo .expanded-folder.selected-folder + div,
	body.geo .collapsed-folder.selected-folder + div,
	body.geo .collapsed-folder {
		border-color: #f6871f !important;
	}

	body.nonprofit #root-folder,
	body.nonprofit .expanded-folder,
	body.nonprofit .expanded-folder + div,
	body.nonprofit .collapsed-folder + div,
	body.nonprofit .expanded-folder.selected-folder,
	body.nonprofit .collapsed-folder.selected-folder,
	body.nonprofit .expanded-folder.selected-folder + div,
	body.nonprofit .collapsed-folder.selected-folder + div,
	body.nonprofit .collapsed-folder {
		border-color: #00d4ad !important;
	}

	body.probono #root-folder,
	body.probono .expanded-folder,
	body.probono .expanded-folder + div,
	body.probono .collapsed-folder + div,
	body.probono .expanded-folder.selected-folder,
	body.probono .collapsed-folder.selected-folder,
	body.probono .expanded-folder.selected-folder + div,
	body.probono .collapsed-folder.selected-folder + div,
	body.probono .collapsed-folder {
		border-color: #00bad4 !important;
	}

	#root-folder .folder-list folderapp > .folder-list::before,
	#root-folder .folder-list folderapp > .folder-list .expanded-folder::after,
	#root-folder .folder-list folderapp > .folder-list .collapsed-folder::after,
	#root-folder .folder-list folderapp > .folder-list folderapp::after {
		background-color: transparent !important;
	}

	.expanded-folder:before,
	.collapsed-folder:before {
		display: none !important;
	}

	body.geo .selected-folder .folder-name,
	body.nonprofit .selected-folder .folder-name,
	body.probono .selected-folder .folder-name {
		color: #000;
	}

	div.folder-view + div.list + #library-display > #root-folder {
		margin-top: 30px !important;
	}

	.folder-info {
		border-left: none;
		padding-left: 0;
	}
}

@media (max-width: 767px) {
	#main {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.hideInDesktop.link {
		width: 180px !important;
		margin-top: -150px !important;
		text-transform: uppercase;
		font-size: 13px !important;
	}

	body.geo .hideInDesktop.link {
		color: #f6871f !important;
	}

	body.nonprofit .hideInDesktop.link {
		color: #00d4ad !important;
	}

	body.probono .hideInDesktop.link {
		color: #00bad4 !important;
	}

	body.geo .hideInDesktop.link:after {
		color: #f6871f !important;
	}

	body.nonprofit .hideInDesktop.link:after {
		color: #00d4ad !important;
	}

	body.probono .hideInDesktop.link:after {
		color: #00bad4 !important;
	}

	content-display.calendarApp .asCalendar ul .rcevent,
	.list ul li.rc {
		width: calc(100% - 8px) !important;
		margin: 7px 7px 15px 4px !important;
	}

	body:not(.library) .content-info::before {
		left: 2px;
	}

	content-display.calendarApp .asCalendar ul .date,
	content-display.calendarApp .asCalendar ul .date .date-number {
		font-size: 24px !important;
		margin-right: 0 !important;
	}

	.back {
		background-color: transparent !important;
	}

	body.geo content-display .list .filters .hotspot,
	body.geo content-display .list .filters .rcMenu.radio.active .rcMenuItem,
	body.geo content-display .list .filters .rcMenu.active .rcMenuItem {
		background-color: #fef2e7 !important;
	}

	body.nonprofit content-display .list .filters .hotspot,
	body.nonprofit
		content-display
		.list
		.filters
		.rcMenu.radio.active
		.rcMenuItem,
	body.nonprofit content-display .list .filters .rcMenu.active .rcMenuItem {
		background-color: #e6fffa !important;
	}

	body.probono content-display .list .filters .hotspot,
	body.probono
		content-display
		.list
		.filters
		.rcMenu.radio.active
		.rcMenuItem,
	body.probono content-display .list .filters .rcMenu.active .rcMenuItem {
		background-color: #e6fcff !important;
	}

	body.geo content-display .list .filters .hotspot,
	body.geo content-display .list .filters .rcMenu.radio.active .rcMenuItem,
	body.geo content-display .list .filters .rcMenu.active .rcMenuItem,
	body.geo content-display .list .filters .rcMenu.active .hotspot {
		border-color: #f57c0a !important;
	}

	body.nonprofit content-display .list .filters .hotspot,
	body.nonprofit
		content-display
		.list
		.filters
		.rcMenu.radio.active
		.rcMenuItem,
	body.nonprofit content-display .list .filters .rcMenu.active .rcMenuItem,
	body.nonprofit content-display .list .filters .rcMenu.active .hotspot {
		border-color: #00cca7 !important;
	}

	body.probono content-display .list .filters .hotspot,
	body.probono
		content-display
		.list
		.filters
		.rcMenu.radio.active
		.rcMenuItem,
	body.probono content-display .list .filters .rcMenu.active .rcMenuItem,
	body.probono content-display .list .filters .rcMenu.active .hotspot {
		border-color: #00b4cc !important;
	}

	content-display .list .filters .hotspot {
		margin-right: 0 !important;
	}

	body.geo content-display .list .filters .filter-indicator,
	body.geo content-display .list .filters .rcMenu.active,
	body.geo content-display .list .filters .rcMenu.active .hotspot {
		background-color: #f57c0a !important;
	}

	body.nonprofit content-display .list .filters .filter-indicator,
	body.nonprofit content-display .list .filters .rcMenu.active,
	body.nonprofit content-display .list .filters .rcMenu.active .hotspot {
		background-color: #00cca7 !important;
	}

	body.probono content-display .list .filters .filter-indicator,
	body.probono content-display .list .filters .rcMenu.active,
	body.probono content-display .list .filters .rcMenu.active .hotspot {
		background-color: #00b4cc !important;
	}

	content-display .list .filters .rcMenu.active .hotspot,
	content-display .list .filters .rcMenu.active .hotspot:after {
		color: #fff !important;
	}

	.filters {
		box-shadow: none !important;
	}

	.filter-indicator {
		color: #fff;
		text-transform: uppercase !important;
	}

	.filter-indicator:after {
		color: #fff !important;
	}

	content-display
		.list
		> .filters
		> span
		> .rcMenu
		.rcMenuItem.rcSelected::after {
		top: 17px !important;
		left: 17px !important;
	}

	.news .page-tools.hmenu {
		margin-bottom: 10px !important;
		margin-top: 20px !important;
	}

	.library .resource {
		margin-left: -10px;
		margin-top: 0px !important;
		float: left !important;
		clear: none !important;
	}

	.library .detail-tools {
		float: left !important;
		clear: none !important;
		margin-bottom: -60px;
		margin-left: 0px;
	}

	#header strong.logo,
	#header strong.logo a {
		z-index: 1 !important;
	}

	#nav {
		z-index: 0 !important;
	}

	.library .filters {
		top: 110px;
	}

	.library .tool-options.accent.spaced a {
		margin-top: -60px;
	}

	.calendar #m-header.head-detail {
		height: 150px;
	}

	.calendar .back {
		margin-top: 0px !important;
		color: #00bad4;
	}

	.calendar .page-tools.hmenu {
		top: -60px !important;
	}

	.calendar .event {
		padding-top: 0;
		margin-top: 0px !important;
	}

	.calendar .content-detail {
		margin-left: 20px;
		width: 70%;
		float: left !important;
	}

	.calendar .detail-tools {
		margin-left: 0px;
	}

	.calendar .detail-tools h4,
	.calendar .detail-tools li a,
	.calendar .detail-tools li a:before {
		color: #1f1f1f !important;
	}

	.calendar .detail-tools li.cleCredit {
		color: #fff !important;
	}
}

/* Nav menu dropdown */
/*
#nav a[href$="home/"] {
	margin-right: 0px !important;
}

#nav a[href$="home/"]:after {
	content: "" !important;
	display: inline-block;
	vertical-align: middle;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 4px 0 5px 5px;
	border-color: transparent transparent transparent #f6871f;
	margin-left: 6px !important;
	margin-top: -1px !important;
}

#nav a[href$="home/"]:hover:after,
#nav
	.js-slide-hidden:first-of-type
	li:first-child:hover
	a[href$="home/"]:after {
	border-width: 5px 5px 0 4px;
	border-color: #999 transparent transparent transparent;
	margin-left: 4px !important;
	margin-right: -2px;
}

#nav .js-slide-hidden:first-of-type li:first-child:hover a[href$="home/"] {
	color: #999;
}

#nav .dropdown-content.js-slide-hidden {
	position: absolute !important;
	left: -9999px;
	top: -9999px;
	background: #fff;
	border-radius: 4px;
	width: 215px;
	padding: 10px !important;
	box-shadow: 0px 10px 11px rgba(0, 0, 0, 0.5);
}

.dropdown-content li {
	float: none !important;
	padding: 14px !important;
	//margin: 0 0 0 50px !important;
	font-size: 14px;
	font-weight: bold;
}
*/
/*
.dropdown-content li a:before {
	content: "";
	width: 28px;
	height: 28px;
	position: absolute;
	left: -35px;
	top: 3px;
	background-size: 28px 28px !important;
}

body.geo .dropdown-content li a[href$="/nonprofit"] {
	color: #00d4ad !important;
}

body.geo .dropdown-content li a[href$="/nonprofit/volunteer"] {
	color: #00bad4 !important;
}

body.geo .dropdown-content li a[href$="/nonprofit"]:hover,
body.geo .dropdown-content li a[href$="/nonprofit/volunteer"]:hover {
	color: #999 !important;
}

body.geo .dropdown-content li a[href$="/nonprofit"]:before {
	background: url("../images/icons/IAN_nonprofit_icon.png") no-repeat;
	top: 12px;
}

body.geo .dropdown-content li a[href$="/nonprofit/volunteer"]:before {
	background: url("../images/icons/IAN_probono_icon.png") no-repeat;
	top: 12px;
}

body.geo .dropdown-content li a[href$="calendar"]:before {
	background: url("../images/icons/calendar_icon_orange.png") no-repeat;
}

body.geo .dropdown-content li a[href$="news"]:before {
	background: url("../images/icons/news_icon_orange.png") no-repeat;
}

body.geo .dropdown-content li a[href$="legaldirectory"]:before {
	background: url("../images/icons/legal_directory_icon_orange.png") no-repeat;
	background-size: 30px 30px !important;
	width: 30px;
	height: 30px;
	top: 5px;
}

body.nonprofit .dropdown-content li a[href$="library"]:before {
	background: url("../images/icons/library_icon_green.png") no-repeat;
	background-size: 24px 28px !important;
	left: -32px;
}

body.nonprofit .dropdown-content li a[href$="calendar"]:before {
	background: url("../images/icons/calendar_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="alerts"]:before {
	background: url("../images/icons/alerts_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="webinars"]:before {
	background: url("../images/icons/webinars_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="podcasts"]:before {
	background: url("../images/icons/podcasts_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="jobs"]:before {
	background: url("../images/icons/jobs_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="news"]:before {
	background: url("../images/icons/news_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="roster"]:before {
	background: url("../images/icons/members_icon_green.png") no-repeat;
}

body.nonprofit .dropdown-content li a[href$="legaldirectory"]:before {
	background: url("../images/icons/legal_directory_icon_green.png") no-repeat;
}

body.probono .dropdown-content li a[href$="library"]:before {
	background: url("../images/icons/library_icon_blue.png") no-repeat;
	background-size: 24px 28px !important;
	left: -32px;
}

body.probono .dropdown-content li a[href$="calendar"]:before {
	background: url("../images/icons/calendar_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="alerts"]:before {
	background: url("../images/icons/alerts_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="webinars"]:before {
	background: url("../images/icons/webinars_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="podcasts"]:before {
	background: url("../images/icons/podcasts_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="jobs"]:before {
	background: url("../images/icons/jobs_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="news"]:before {
	background: url("../images/icons/news_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="members"]:before {
	background: url("../images/icons/members_icon_blue.png") no-repeat;
}

body.probono .dropdown-content li a[href$="volunteer"]:before {
	background: url("../images/icons/volunteer_guide_icon_blue.png") no-repeat;
}
*/
.dropdown-content li a {
	color: #f6871f !important;
}

.dropdown-content li a:hover {
	color: #999 !important;
}

#nav
	.js-slide-hidden:first-of-type
	li:first-child:hover
	.dropdown-content.js-slide-hidden {
	left: -30px !important;
	top: 20px !important;
}

@media (max-width: 940px) {
	#nav ul li a {
		font-size: 13px;
	}
}

@media (max-width: 767px) {
	#nav a[href$="home/"]:after {
		display: none;
	}

	#nav ul li a {
		font-size: 15px;
	}

	.dropdown-content {
		display: block;
		position: relative;
		left: 0;
		top: 0;
		background: none !important;
		box-shadow: none;
		padding: 0 !important;
	}

	.dropdown-content li {
		margin: 0 !important;
		background: none !important;
		padding: 0 !important;
	}

	.dropdown-content li:hover {
		background: #f6a01f !important;
	}

	.dropdown-content li a:hover {
		color: #000 !important;
	}
}

content-display .list .filters .rcMenu .rcMenuItem {
	padding: 3px 9px 3px 25px !important;
}

content-display .list .filters .rcMenu .rcMenuItem:before {
	top: 8px !important;
	margin: 0px 6px 0px -1px !important;
}

content-display .list .filters .rcMenu.radio .rcMenuItem:before {
	margin: 0px 6px 0px -1px !important;
}

.calendar #m-header.head-detail .page-tools.hmenu:not(.lower) {
	top: 0px !important;
}
