// NProgress
@import "nprogress/nprogress";

// Colors
$white: #ffffff;
$black: #000000;
$martinique: #33294a;
$tacha: #d2c35f;
$international-orange: #ff5900;
$sail: #aac8fa;
$gallery: #f0f0f0;

// Site styles (old)
@import "pbn/all";

// Icon set
@import "../../pbn-icons/scss/app";

// Fonts
@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
	font-style: normal;
	font-weight: 100 700;
}
@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Italic-VariableFont_wght.ttf")
		format("truetype");
	font-style: italic;
	font-weight: 100 700;
}

// Site style fixes
body {
	font-family: "Helvetica Neue", system-ui, -apple-system, "Segoe UI", Roboto,
		Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Montserrat", system-ui, -apple-system, "Segoe UI", Roboto,
		Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
	font-weight: 600 !important;
}
.home {
	.visual {
		.banner-image {
			width: 992px;
			display: block !important;
		}
	}
	.column.yellow,
	.column.blue {
		img {
			display: block !important;
		}
	}
}
.fav-icon {
	background-image: url(../images/Favicon_IAN.png);
}
@media (max-width: 767px) {
	body .visual .caption {
		background: $martinique !important;
	}
}
body.nonprofit .calendar-style-nav a,
body.nonprofit h2#ToolTitle,
body.nonprofit h2.tool,
body.nonprofit h2.tool-title,
body.nonprofit div.content-detail h3.summary,
body.geo .calendar-style-nav a,
body.geo h2#ToolTitle,
body.geo h2.tool,
body.geo h2.tool-title,
body.geo div.content-detail h3.summary h2.tool {
	color: $martinique !important;
	margin-left: 0 !important;
	&::before {
		background: none !important;
	}
}
body.nonprofit #toolicons .text:after,
body.probono #toolicons .text:after {
	color: $martinique !important;
}
#footer {
	background: transparent !important;
	ul {
		font-size: 1rem;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		a {
			width: 2.5rem;
			height: 2.5rem;
			background-color: $martinique;
			color: $white;
			border-radius: 50%;
			text-align: center;
			display: inline-block;
			padding-top: 0.75rem;
			text-decoration: none;
			transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
			&:hover {
				background-color: $white;
				color: $martinique;
			}
		}
	}
}
h3.priority {
	padding-left: 0 !important;
}
