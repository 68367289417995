/* element styles */
* {
	padding: 0;
	margin: 0;
}

body {
	font-family: Georgia, serif;
	font-size: small;
	color: #222;
}
address {
	font-style: normal;
}
a img {
	border: 0;
}
fieldset {
	border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	clear: both;
}
hr {
	height: 0.1em;
	border: 0;
	background-color: #ccc;
}
.content-item h4 {
	font-weight: bold;
}
h3 {
	font-size: 140%;
	clear: both;
}
.content-item h3 {
	margin: 0.5em 0;
	line-height: 1.5em;
}
caption {
	text-align: left;
}
p {
	margin: 0.5em 0;
	line-height: 1.5em;
}
table td {
	padding: 0 1em;
}
.accessibility {
	display: none;
}
.block {
	display: block;
	clear: both;
}

form fieldset .instruct {
	font-weight: bold;
}
h3 form.admin-ctrls {
	padding-top: 0.5em;
	display: inline;
}
.floatLeft {
	float: left;
}
.floatRight {
	float: right;
}
.clear {
	clear: both;
}
.centered {
	text-align: center;
}
.t-rght {
	text-align: right;
}
.emphasis {
	font-style: italic;
}
.Indent1 {
	margin-left: 2em;
}

.Indent2 {
	margin-left: 4em;
}

.Indent3 {
	margin-left: 6em;
}
.priority {
	//background: url(/images/icons/priority.png) no-repeat center left;
	padding-left: 20px;
}
