@font-face {
	font-family: "picons-font";
	src: url("../fonts/Picons-Font.ttf");
	src: url("../fonts/Picons-Font.ttf") format("truetype"),
		url("../fonts/Picons-Font.woff") format("woff"),
		url("../fonts/Picons-Font.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

body {
	background: #ccc;
}

#ical-link {
	position: absolute;
	display: none;
	right: -10px;
	top: 10px;
}

.success-msg {
	clear: both;
	margin: 4em 0;
	padding: 1em;
}

.offset {
	padding: 0.8em;
}

.no-records {
	text-align: center;
	margin: 2em 0;
}

.video {
	background: url(../images/icons/iconVideo.gif) no-repeat center right;
	padding-right: 55px;
}

.topic {
	background: url(../images/icons/topic.gif) 0 center no-repeat;
	padding-left: 20px;
}

sup.national {
	font-size: 0.8em;
	color: #ff0000;
}

a.refine {
	font-size: 0.8em;
	font-style: italic;
}

a.external {
	background: url(../images/icons/external.png) no-repeat center right;
	padding-right: 12px;
}

div.alert {
	background-image: url(../images/icons/alert.gif);
	background-repeat: no-repeat;
	background-position: center left;
	padding-left: 20px;
}

div.vcard-dl {
	background-image: url(../images/icons/vcard.png);
	background-repeat: no-repeat;
	background-position: center left;
	padding-left: 20px;
}

ul.resources .link,
div.content-summary.link {
	background: url(../images/icons/link.png) 0 center no-repeat;
	padding-left: 20px;
}

div.content-summary.document,
div.content-summary.ct9 {
	background: url(../images/icons/doc.png) 0 center no-repeat;
	padding-left: 25px;
}

ul.resources .document {
	background: url(../images/icons/doc.png) 0 no-repeat;
	padding-left: 20px;
	background-position: 0px 2px;
}

.folder {
	background: url(../images/icons/folder.png) 0 center no-repeat;
	padding-left: 20px;
}

ul.resources .webcast {
	background: url(../images/icons/webcast.gif) 0 center no-repeat;
	padding-left: 55px;
}

div.body dl dd,
dl.body dd {
	margin-bottom: 1em;
}

abbr.dtstart,
abbr.dtend {
	border: 0;
}

a.moreresults {
	font-size: 1.2em;
	font-weight: bold;
	background: url(../images/more-link-arrow.gif) 5px center no-repeat;
	padding: 0.3em 0.3em 0.3em 15px;
	margin: 0.5em 0;
	float: left;
	clear: both;
	display: block;
}

a.more {
	background: url(../images/more-link-arrow.gif) 0 5px no-repeat;
	padding-left: 10px;
}

a.return {
	background: url(../images/return-link-arrow.gif) 0 5px no-repeat;
	padding-left: 10px;
}

dl.horz {
	list-style-type: none;
	display: block;
	clear: both;
	float: left;
}

dl.horz dt {
	float: left;
	line-height: 2em;
}

dl.horz dd {
	float: left;
	/* IE Problem */
	line-height: 2em;
	padding-right: 0.5em;
}

dl.lined dt {
	clear: both;
}

/* LAYOUT */

div.section {
	clear: both;
	width: 100%;
	float: left;
}

div#content {
	padding: 1em 2em;
}

div#body {
	margin-bottom: 3em;
}

body {
	padding: 0 3%;
}

/* width fixed centered */
/* left fixed nav, flex body  */
div#container {
	width: 100%;
	float: left;
	text-align: left;
	margin: 0 auto;
}

div#body {
	float: left;
	width: 100%;
}

div#content {
	margin-left: 200px;
}

/*  float:left; - if this is added smaller width portions dont streach to 100%  */
div#side {
	float: left;
	width: 200px;
	margin-left: -100%;
}

div#container {
	min-width: 800px;
}

/* END LAYOUT */

#tool-nav li.first a {
	margin-left: 0.5em;
}

#tool-nav {
	list-style-type: none;
	overflow: hidden;
	float: left;
	width: 100%;
}

#tool-nav li {
	float: left;
	width: auto;
}

#tool-nav li a {
	text-decoration: none;
	font-weight: bold;
	display: block;
	float: left;
	width: auto;
	padding: 0.7em;
	z-index: 10;
	overflow: hidden;
}

#tool-nav li.login {
	position: relative;
	float: right;
}

#tool-nav li.back {
	width: 9px;
	height: 30px;
	z-index: 8;
	position: absolute;
	cursor: pointer;
}

.submit {
	border: 0;
	font-family: Georgia, serif;
	font-size: 90%;
	font-weight: bold;
	padding: 0.3em 0.5em;
}

.cleCredit {
	font-weight: bold;
	font-size: 0.9em;
}

abbr {
	cursor: help;
}

#content .submit {
	padding: 0.5em 1em;
}

input {
	padding: 0.2em;
}

form fieldset ul {
	margin: 0;
	padding: 0;
}

form fieldset li {
	margin: 0.5em 0;
}

form fieldset li.controls {
	text-align: right;
}

form fieldset select optgroup option {
	padding-left: 1em;
}

div.message {
	padding: 0.2em 0;
	width: 100%;
	border-top: 1px solid #999;
	border-bottom: 2px solid #ccc;
	background-color: #eee;
}

/* used in the join form */
div.message h3 {
	background: #eee url(../images/icons/message_32.gif) 10px center no-repeat;
	padding-left: 50px;
	line-height: 2em;
}

div.message div.detail {
	padding-left: 50px;
	font-style: italic;
}

div.frmMsg {
	padding: 0.2em 0 0.2em 40px;
	background-image: url(../images/icons/message_32.gif);
	background-repeat: no-repeat;
	background-position: 0.2em 5px;
}

div.frmMsg p {
	font-style: italic;
}

#side form {
	padding: 1em 2em 0 1em;
}

#side legend.login {
	padding-left: 12px;
}

#side h2.join {
	padding: 1em 1em 1em 25px;
}

#side h2.join a:hover,
#side legend.login a:hover {
	background-color: inherit;
	text-decoration: none;
}

#side #powered-by {
	padding: 1em;
}

#title-bar {
	padding: 1em 0;
}

#identity {
	padding: 0 0 0 1em;
}

#identity {
	float: left;
}

#identity h1 {
	font-size: 260%;
	margin: 0;
}

#identity h1 a {
	text-decoration: none;
	font-weight: normal;
}

#identity h1 a:hover {
	text-decoration: underline;
}

#identity h2 {
	font-size: 110%;
	margin: 0;
}

#side h2,
#side div.module h3 {
	font-size: 140%;
	padding: 0.5em 1em 0.5em 0.6em;
	margin-bottom: 0;
	margin-top: 0;
}

#side h2 span {
	font-size: 60%;
	text-transform: uppercase;
	display: block;
}

div.w_wysiwyg {
	clear: both;
}

div#side div.w_wysiwyg div.c_wysiwyg h3,
div#callout div.w_wysiwyg div.c_wysiwyg h3 {
	background-color: inherit;
	padding: 0;
	margin: 0;
}

#side div.w_wysiwyg div.c_wysiwyg ol,
#side div.w_wysiwyg div.c_wysiwyg ul {
	padding: 0 1.5em;
	margin: 1em 0;
}

#side div.w_wysiwyg div.c_wysiwyg ul {
	list-style-type: disc;
}

form#geo_switch {
	padding-top: 0;
}

form#geo_switch select {
	width: 100%;
}

#side ul {
	list-style-type: none;
}

#side ul.nav {
	padding: 0.8em 1em;
}

#side ul.nav li {
	margin-bottom: 0.5em;
	line-height: 1.4em;
}

#side ul.nav a {
	padding: 0.2em 0;
}

#side ul.nav a:hover {
	text-decoration: none;
}

#content h1 {
	clear: both;
	font-size: 240%;
	font-style: italic;
}

#content h2 {
	font-size: 240%;
}

/* content item summary */
div.group {
	float: left;
	display: block;
	clear: both;
	width: 100%;
	margin: 1em 0;
}

div.group h3 {
	border-bottom: 1px solid #ccc;
}

div.pagnation {
	text-align: right;
	clear: both;
	padding: 0.5em 0;
	float: left;
	width: 100%;
}

div.pagnation span,
div.pagnation div {
	margin: 0 0.5em;
}

div.pagnation span.rows_shown {
	float: left;
}

div.pagnation .opt {
	display: inline;
	padding-right: 1em;
}

div.pagnation a.next {
	background: url(../images/icons/nav_arrowright.gif) center right no-repeat;
	padding-right: 10px;
	margin: 0 0.5em;
}

div.pagnation a.prev {
	background: url(../images/icons/nav_arrowleft.gif) center left no-repeat;
	padding-left: 10px;
	margin: 0 0.5em;
}

div.pagination .current {
	text-align: center;
	padding: 7px 10px 1px;
	float: left;
}

#side ul.nav a.current {
	font-weight: bold;
	background: url(../images/icons/nav_arrowright.gif) center right no-repeat;
	padding-right: 10px;
}

.item-summary {
	margin: 0.5em;
}

ul.item-summary {
	list-style-type: none;
}

.item-summary li {
	float: left;
	clear: both;
	margin: 0.5em 0;
}

.item-summary h4 {
	font-size: 1.2em;
}

.content-item dl {
	margin: 1em 0;
}

.item-summary dt,
.content-item dt,
dl.horz dt {
	font-weight: bold;
}

.item-summary dd,
.content-item dd,
dl.horz dd {
	margin: 0 0.5em 0 0.2em;
}

div#content div#m-header h2.tool-title {
	float: left;
	clear: none;
}

div.tool-options {
	clear: both;
	padding: 0.5em;
}

div.tool-options div.current {
	text-align: center;
	float: left;
}

div.tool-options div.tool-prev {
	float: left;
	margin-right: 1em;
}

div.tool-options div.tool-prev a,
div.tool-options div.tool-next a {
	padding: 0.2em;
}

div.tool-options div.tool-next {
	float: right;
	text-align: left;
}

span.active {
	font-weight: bold;
}

ul.summary,
ul.summary li {
	clear: both;
}

/* end tool css */

/* common color styles */
div#container {
	background-color: #fff;
	/*border-right: 2px solid #ddd;*/
}

div.header {
	background: #fff;
}

#header-right {
	float: right;
	padding: 0 2em;
}

#header-right a:hover {
	text-decoration: none;
}

div.header a {
	text-decoration: none;
}

div.header a:hover {
	background-color: inherit;
	text-decoration: underline;
}

#footer {
	border-top: 2px #f3efc6 solid;
	background-color: #f9f8eb;
}

#footer .h {
	color: #f9f8eb;
}

/* end color styles */

/* Specific to tool pages */
ul.hmenu {
	list-style-type: none;
	margin: 0;
}

ul.hmenu li {
	float: left;
	width: auto;
	border-left: 1px solid #999;
	border-right: 0;
	padding-left: 5px;
	margin-left: 8px;
}

ul.hmenu li.first {
	border-left: 0;
	padding-left: 0;
	margin-left: 0;
}

ul.page-tools {
	font-size: 90%;
	float: right;
	width: auto;
}

ul.page-tools a {
	padding-top: 0.2em;
	padding-bottom: 0.2em;
}

ul.page-tools a.email,
div.pagnation a.email {
	background: url(../images/icons/email.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.print {
	background: url(../images/icons/printer.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.add-item {
	background: url(../images/icons/document.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.add-folder {
	background: url(../images/icons/folder.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.settings {
	background: url(../images/icons/cog.png) 5px center no-repeat;
	padding-left: 25px;
}

a.rss {
	background: url(../images/icons/rss.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.admin {
	background: url(../images/icons/doc.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.widget {
	background: url(../images/icons/widget.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.home {
	background: url(../images/icons/application_home.png) 5px center no-repeat;
	padding-left: 25px;
}

ul.page-tools a.hide-sidebar {
	background: url(../images/icons/application_sidebar.png) 5px center
		no-repeat;
	padding-left: 25px;
}

ul.page-tools a.external-content {
	background: url(../images/icons/application_external.png) 5px center
		no-repeat;
	padding-left: 25px;
}

ul.page-tools a.reports {
	background: url(../images/icons/application_reports.png) 5px center
		no-repeat;
	padding-left: 25px;
}

ul.page-tools a.labels {
	background: url(../images/icons/table_edit.png) 5px center no-repeat;
	padding-left: 25px;
}

/* Common result set more link */
div.resultset a.moreresults {
	background: url(../images/icons/arrow_right.gif) center right no-repeat;
	padding-right: 20px;
	margin: 0.5em 0 1em 0.5em;
	float: left;
	clear: both;
}

/* clears the border */
.last {
	border-right: 0 !important;
}

/* new compatiable css*/

#callout ul.feed li {
	list-style-type: none;
	margin-bottom: 1em;
}

#callout h3 {
	padding: 0.5em;
}

#notice h2 {
	line-height: 1.2em;
}

#side h3 {
	font-size: 1em;
	margin-bottom: 0.5em;
}

#side div.offset,
#side div.logo-app {
	padding: 1em;
}

#content div.logo-app h3 {
	padding: 0.5em;
}

#content div.logo-app .logo-list ul li a {
	display: inline;
}

div.c_wysiwyg ul,
div.c_wysiwyg ol {
	margin: 1em 0 1em 2em;
}

div#side div.c_wysiwyg,
div#callout div.c_wysiwyg {
	padding: 1em;
}

div#side div.w_wysiwyg h2 {
	background-color: inherit;
	padding: 0;
}

/* start wysiwyg styles */

/* header styles */
div#body div#content .c_wysiwyg h2 {
	line-height: normal;
	margin: 0.5em 0 0 0;
}

div#body div#content .c_wysiwyg h3,
div#body div#content .c_wysiwyg h4,
div#body div#content .c_wysiwyg h5,
div#body div#content .c_wysiwyg h6 {
	line-height: normal;
	margin: 0.8em 0 0 0;
}

div#body div#content .c_wysiwyg h3 {
	font-weight: bold;
	font-size: 175%;
}

div#body div#content .c_wysiwyg h4 {
	font-size: 175%;
}

div#body div#content .c_wysiwyg h5 {
	font-size: 125%;
	font-weight: bold;
}

div#body div#content .c_wysiwyg h6 {
	font-size: 125%;
}

div#body div#content .c_wysiwyg h4.subtitle {
	margin: 0 0 0.5em 0;
	line-height: normal;
	font-weight: normal;
	font-style: italic;
	font-size: 120%;
	line-height: 1.5em;
}

div#body div#content .c_wysiwyg em.intense {
	font-weight: bold;
}

cite {
	font-family: monospace;
}

blockquote {
	margin: 2em 0 2em 2em;
	padding-left: 1em;
	border-left: 3px solid #ccc;
}

address {
	margin: 2em 0;
}

div#body div#content .c_wysiwyg p,
div#body div#content .c_wysiwyg div {
	clear: both;
}

div#body div#content .c_wysiwyg ol ol,
div#body div#content .c_wysiwyg ol ul,
div#body div#content .c_wysiwyg ul ol,
div#body div#content .c_wysiwyg ul ul {
	margin: 0 0 0 1em;
}

div.c_wysiwyg {
	line-height: 1.5em;
}

div.c_wysiwyg img {
	margin: 0.5em 1em;
}

div.c_wysiwyg img.left {
	float: left;
	margin: 1em 1em 1em 0;
}

.spaced {
	margin: 2em 0;
}

div.c_wysiwyg span.spaced {
	margin: 0 1em;
}

div.c_wysiwyg p.outline {
	border: 2px solid #ccc;
	padding: 1em;
}

img.right {
	float: right;
	margin: 1em 0 1em 1em;
}

span.HighlightDark,
span.HighlightLight {
	padding: 0 0.2em;
}

p.HighlightLight,
p.HighlightMid,
p.HighlightMidAlt {
	padding: 1em;
}

/* end wysiwyg property styles */

div.group h3 em {
	font-style: normal;
	font-size: 0.8em;
}

div.content-summary {
	clear: both;
	padding-top: 1em;
	padding-bottom: 1.5em;
}

div.content-summary h4 {
	font-size: 1.2em;
}

div.content-summary h4 a,
div.content-summary.ct9 h4 {
	font-weight: bold;
}

div.content-summary p {
	clear: both;
	margin: 0.5em 0;
}

div.content-summary ul.props {
	margin: 0.5em 0;
}

div.content-summary ul.props li {
	float: left;
	margin-right: 1em;
	line-height: 1.5em;
}

div.content-summary ul.props li strong {
	margin-left: 0.3em;
}

div.content-summary ul.props li.lb {
	clear: left;
	margin-top: 0.5em;
	width: 95%;
}

div.ct5.folders ul.props {
	margin: 0.5em 0 1em 1.5em;
}

div.ct5.folders ul.props li {
	color: #999;
}

div.content-summary.ct11 li {
	clear: left;
	margin-top: 0.2em;
	width: 95%;
}

div.content-summary.flx div.leadin {
	clear: both;
	float: left;
	margin: 0.5em 1em 0.5em 1em;
	font-size: 0.95em;
}

div.content-detail h4 {
	font-weight: bold;
	margin: 1em 0;
}

div.content-detail ul.props li {
	margin: 1em 0;
}

ul.props,
ul.props em {
	font-style: normal;
	font-weight: bold;
	list-style-type: none;
}

ul.props strong,
ul.props .adr,
ul.props .adr div,
ul.props .adr span,
div.content-detail div.vcard,
div.eventlocationname {
	font-weight: normal;
	color: #000;
	line-height: 1.5em;
}

ul.props a {
	font-weight: normal;
	line-height: 1.5em;
}

ul.props div.attachments {
	margin-left: 20px;
}

ul.props div.attachments a {
	margin-right: 0.5em;
}

ul.subtopics li {
	font-size: 0.9em;
	float: left;
	margin-right: 1em;
	line-height: 2em;
	white-space: nowrap;
}

div.mediaplayer {
	padding: 2em 0;
}

/* content detail layout header */
div#m-header {
	margin-bottom: 1em;
}

div#m-footer {
	float: left;
	clear: both;
	margin: 1em 0;
}

/* content type specific styles */
div.ct3 .contact .vcard {
	display: inline;
}

div.content-detail.ct11 li.contact div {
	font-weight: normal;
	line-height: 1.5em;
	color: #000;
}

div#callouts div.c_wysiwyg {
	padding: 1em;
}

div.content-detail div.map {
	margin: 1em 0;
}

div.accent.alert,
div.accent.vcard-dl {
	margin: 1em 0;
	padding: 0.5em 0.5em 0.5em 20px;
}

div.topics {
	margin: 2em 0 0 0 !important;
}

div.topics strong {
	float: left;
	margin-right: 1em;
}

div.topics ul {
	float: left;
	display: inline;
	list-style-type: none;
}

div.topics ul li {
	float: left;
	margin: 0.3em 1.5em 0.3em 0;
}

.cleCredit {
	color: #ff6600 !important;
}

/* staff page */
div.staff {
	padding-right: 3em;
}

div.staff ul {
	margin: 0;
	padding: 0;
}

div.staff li {
	margin-bottom: 1em;
	border-bottom: 5px solid #eee;
	list-style-type: none;
}

div.staff li.img {
	padding-right: 150px;
}

/* News Topic TOC */
.TopicPanel {
	margin-left: 20px;
	float: left;
	width: 320px;
	padding-bottom: 20px;
}

#NewsTopics {
	margin-top: 15px;
}

#NewsTopicsMore {
	margin-left: 10px;
}

li.NewsTopic {
	list-style: none;
	padding-top: 8px;
}

.DisabledTextField {
	border: 1px solid #999 !important;
	background-color: #f4f4f4 !important;
	color: gray;
	opacity: 0.3;
}

.TopicsMore {
	font-size: small;
	color: #a80101;
}

/* Case tool styles */
.case-status {
	font-weight: bold;
	font-size: 0.9em;
}

.case-status.open {
	color: green !important;
}

.case-status.pending {
	color: #ff6600 !important;
}

.case-status.placed {
	color: red !important;
}

.case-status.closed {
	color: red !important;
}

.case-controls {
	clear: both;
	padding-top: 20px;
	padding-bottom: 20px;
}

.case-action {
	margin-right: 10px;
	padding-left: 20px;
}

.case-action.review {
	background: url(../images/icons/review.png) 0 center no-repeat;
}

.case-action.accept {
	background: url(../images/icons/accept.png) 0 center no-repeat;
}

.case-action.reject {
	background: url(../images/icons/reject.png) 0 center no-repeat;
}

.case-action.close {
	background: url(../images/icons/close.png) 0 center no-repeat;
}

ul.agenda {
	/*list-style: none;*/
	margin-left: 1em;
}

ul.agenda > li > span:nth-of-type(1) {
	font-weight: bold;
	/*margin-left: 1em;*/
}

/* Item: 8421 fix */
div#content div.description ul,
div#content div.description ol {
	padding-left: 2em;
}

div#content ul.props {
	padding-left: 0 !important;
}

div#content div.topics ul {
	padding-left: 0 !important;
}

h3.expandable {
	cursor: pointer;
	display: block;
	position: relative;
}

h3 div.descriptiveText {
	font-weight: normal;
	color: #000;
	font-size: 12px;
}

h4.expandable {
	margin: 0px;
	font-size: 1em;
	cursor: pointer;
	display: block;
	position: relative;
	font-weight: bold;
	border: 1px solid #ddd;
	border-left: 3px solid #888;
	transition: all 0.15s ease-out;
	padding-top: 0.4em;
	padding-right: 10.5em;
	padding-bottom: 1.1em;
	padding-left: 0.6em;
}

[class^="labelinstructions"] {
	text-transform: none;
	/* content: "+"; */
	background: none;
	color: inherit;
	font: 18px/16px "picons-font";
	text-align: right;
	right: 8px;
	position: absolute;
	padding-top: 8px;
}

.expandabletitle {
	float: left;
	padding-top: 5px;
	padding-right: 15px;
}

.answerbox {
	border: 1px solid #dddddd;
	padding: 0.5em 1em;
	margin: 0px !important;
}

.underline {
	border-bottom: 1px solid gray;
}

ul.resources div.description {
	margin-left: 20px;
	font-weight: normal;
	color: #000;
}

/* Collections styles */
div.content-summary.guide {
	padding-left: 2%;
}

div.content-detail.guide div.sections {
	padding-left: 2%;
}

/* projects search */
form#projects-search-form {
	margin-bottom: 15px;
	width: 750px;
}

form#projects-search-form div.controls {
	margin: 15px 0;
}

form#projects-search-form fieldset {
	background-color: #ffffff;
	border-top: none !important;
	margin: 0.5em 0 !important;
}

form#projects-search-form li {
	display: inline;
	clear: none;
	float: left;
	width: auto;
	margin-right: 10px;
}

form#projects-search-form select,
form#projects-search-form input.text {
	width: 236px;
	display: block;
	padding: 2px;
	margin: 0;
	clear: left;
}

form#projects-search-form label {
	display: block;
	float: left;
	width: auto;
	clear: right;
	font-size: 12px;
}

/*flex page tool dropdowns*/
.menu {
	position: relative;
}

.menu a {
	text-decoration: none;
}

.menu .arrow {
	font-size: 11px;
	line-height: 0%;
}

.menu > ul > li {
	display: inline-block;
	/*    position:relative;*/
}

.menu > ul > li > a {
	padding: 10px 10px;
	font-weight: bold;
	display: inline-block;
}

.menu .sub-menu {
	/*max-height:450px;
    overflow-y:auto;*/
	position: absolute;
	top: 33px;
	z-index: 20;
	display: none;
}

.menu .sub-menu > div {
	float: left;
	max-width: 150px;
}

.menu li:hover .sub-menu {
	display: block;
}

.menu .sub-menu li {
	display: block;
}

#tool-nav .sub-menu > div > li > a {
	font-weight: normal;
}
