@font-face {
	font-family: "dinbold";
	src: url("../fonts/din-bold-webfont.eot");
	src: url("../fonts/din-bold-webfont.eot?#iefix") format("embedded-opentype"),
		url("../fonts/din-bold-webfont.woff2") format("woff2"),
		url("../fonts/din-bold-webfont.woff") format("woff"),
		url("../fonts/din-bold-webfont.ttf") format("truetype"),
		url("../fonts/din-bold-webfont.svg#dinbold") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "din_mediumregular";
	src: url("../fonts/din-medium-webfont.eot");
	src: url("../fonts/din-medium-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/din-medium-webfont.woff2") format("woff2"),
		url("../fonts/din-medium-webfont.woff") format("woff"),
		url("../fonts/din-medium-webfont.ttf") format("truetype"),
		url("../fonts/din-medium-webfont.svg#din_mediumregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
* {
	max-height: 100000em;
}
body {
	margin: 0;
	color: #7a7870;
	font: 15px/20px "din_mediumregular", Arial, Helvetica, sans-serif;
	background: #fff url(../images/background.jpg);
	min-width: 999px;
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block;
}
img {
	border-style: none;
}
a {
	text-decoration: none;
	color: #2482d1;
	outline: none;
}
a:hover {
	text-decoration: underline;
}
input,
textarea,
select {
	font: 100% Arial, Helvetica, sans-serif;
	vertical-align: middle;
	color: #000;
}
textarea {
	overflow: auto;
	resize: vertical;
	vertical-align: top;
}
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
}
input[type="search"] {
	-webkit-appearance: none;
}
input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
input[type="search"]::-webkit-search-decoration {
	display: none;
}
q {
	quotes: none;
}
q:before,
q:after {
	content: "";
	content: none;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
p {
	margin: 0 0 1.2em;
}
input[type="text"],
input[type="password"],
input[type="file"],
textarea {
	-webkit-appearance: none;
}
input[type="text"],
input[type="checkbox"],
input[type="password"],
input[type="file"],
input[type="submit"],
input[type="email"],
input[type="tel"],
textarea {
	/*-webkit-appearance: none;*/
	-webkit-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	border-radius: 0 !important;
}
::-webkit-input-placeholder {
	color: #7a7a7a;
}
:-moz-placeholder {
	color: #7a7a7a;
}
::-moz-placeholder {
	color: #7a7a7a;
	opacity: 1;
}
:-ms-input-placeholder {
	color: #7a7a7a;
}
#wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
}
#header:after {
	content: "";
	display: block;
	clear: both;
}
#header {
	max-width: 992px;
	margin: 0 auto;
	background: #fff;
	padding: 0 35px 13px 48px;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.22);
	position: relative;
	z-index: 5;
}
.home #header {
	box-shadow: 0 -9px 12px 0 rgba(0, 0, 0, 0.22);
}
.logo {
	float: left;
	padding: 22px 0 0;
	position: relative;
}
.logo:before {
	position: absolute;
	left: -32px;
	top: 27px;
	content: "";
	background: url(../images/sprite.png) no-repeat -2px -1px;
	width: 10px;
	height: 79px;
}
.logo:after {
	position: absolute;
	left: -32px;
	top: 105px;
	content: "";
	background: url(../images/sprite.png) no-repeat -33px -49px;
	width: 186px;
	height: 40px;
	z-index: 1;
}
.home .logo:after {
	background: url(../images/sprite.png) no-repeat -33px -3px;
	width: 176px;
	height: 19px;
	left: -21px;
}
#header .links-holder {
	float: right;
	width: 80%;
}
.links-holder .lang {
	overflow: hidden;
	margin: 0;
	padding: 0 16px 17px 0;
	list-style: none;
}
.links-holder .lang li {
	float: right;
	position: relative;
	padding: 8px 0 0;
}
.links-holder .lang li:after,
#nav ul li:after {
	background: #fed000;
	width: 2px;
	height: 23px;
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	margin: 0 0 0 -11px;
}
#nav ul li:after {
	height: 9px;
	top: 2px;
	margin: 0 0 0 -10px;
}
.lang li a {
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	color: #171717;
	font-size: 12px;
	line-height: 19px;
}
#nav {
	float: right;
}
.opener {
	display: none;
}
#nav .js-slide-hidden {
	position: static !important;
}
#nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
#nav ul li {
	float: left;
	position: relative;
	margin: 0 0 0 17px;
}
#nav ul li:first-child:after {
	background: none;
}
#nav ul li a {
	text-decoration: none;
	color: #171717;
	font-size: 12px;
	line-height: 15px;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: top;
}
.lang li a:hover,
#nav ul li a:hover {
	color: #999;
}
.home #main {
	padding: 0;
}
#main {
	z-index: 3;
	position: relative;
	max-width: 992px;
	margin: 0 auto;
	overflow: hidden;
	background: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
	padding: 50px 0 0;
}
.visual {
	overflow: hidden;
	position: relative;
	margin: 0 0 26px;
}
.visual img {
	display: block;
}
.visual .caption {
	position: absolute;
	right: 0;
	top: 0;
	width: 307px;
	height: 100%;
	//background: #fed000;
	padding: 39px 36px 0 0;
	border-right: 1px solid #fff;
	text-transform: uppercase;
	color: #171717;
}
.visual .caption:after {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 131px 337px 0;
	border-color: transparent $international-orange transparent transparent;
	position: absolute;
	left: -131px;
	top: 0;
	content: "";
	margin: 0;
}
.visual h1 {
	margin: 00 0 14px;
	font-size: 32px;
	line-height: 32px;
	font-weight: 400;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: -2px;
}
.visual p {
	margin: 0 0 17px;
}
.visual .more {
	display: inline-block;
	vertical-align: top;
	//background: #f2f2f2;
	background: $international-orange;
	//color: #171717;
	color: $white;
	line-height: 25px;
	padding: 0 8px;
	border-radius: 4px;
	text-decoration: none;
	border: solid $international-orange;
	border-width: 0 0 1px;
	text-align: center;
}
.visual .more:hover {
	background: #f57c0a;
}
.heading {
	overflow: hidden;
	text-align: center;
	text-transform: uppercase;
	margin: 0 0 32px;
	font-size: 12px;
	font-family: "dinbold", Arial, Helvetica, sans-serif;
	color: #171717;
}
.heading p {
	margin: 0;
}
.column-holder {
	overflow: hidden;
	margin: 0 12px 45px;
}
.column-holder .column {
	display: inline-block;
	vertical-align: top;
	width: 300px;
	margin: 0 10px;
	background: #fffbe8;
	border-bottom: 11px solid #fed000;
}
.column-holder .column.blue {
	background: #f6fbfe;
	border-bottom: 11px solid #9bd7ef;
}
.column-holder .column.green {
	background: #fafdf7;
	border-bottom: 11px solid #cceba3;
}
.column-holder img {
	display: block;
	margin: 0 0 14px;
}
.column-holder a:hover img {
	opacity: 0.7;
}
.column-holder .text {
	overflow: hidden;
	padding: 0 20px;
	min-height: 101px;
	font-size: 13px;
	line-height: 14px;
}
.column-holder h2 {
	margin: 0 0 9px;
	padding: 0 50px 0 0;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	text-transform: uppercase;
}
.column-holder h2 a {
	text-decoration: none;
	color: #171717;
}
.column-holder h2 a:hover {
	color: #999;
}
.column-holder p {
	margin: 0 0 10px;
}
.news-holder {
	overflow: hidden;
	margin: 0 10px 47px 14px;
}
.news-holder .news {
	display: inline-block;
	vertical-align: top;
	width: 300px;
	margin: 0 10px;
}
.news-holder h2,
.news-holder h3 {
	text-transform: uppercase;
	margin: 0 0 15px;
	font-size: 15px;
	line-height: 17px;
	font-weight: 400;
	color: #171717;
}
.news-holder h2 a,
.news-holder h3 a {
	color: #171717;
}
.news-holder ul {
	margin: 0 !important;
	padding: 0;
	list-style: none;
	font-size: 13px;
	line-height: 16px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.news-holder ul li:first-child {
	border: 0;
}
.news-holder ul li:after {
	position: absolute;
	left: 0;
	top: 13px;
	content: "";
	background: #fed000;
	width: 4px;
	height: 4px;
}
.news-holder ul li:first-child:after {
	top: 6px;
}
.news-holder ul li:first-child {
	padding: 0 0 9px 12px;
}
.news-holder ul li {
	padding: 6px 0 12px 12px;
	border-top: 1px solid #ffefa7;
	position: relative;
}
.news-holder .blue li {
	border-top: 1px solid #dcf1f9;
}
.news-holder .green li {
	border-top: 1px solid #e7f6d4;
	padding: 6px 0 9px 12px;
}
.news-holder ul li a {
	text-decoration: none;
	color: #a2a2a2;
}
.news-holder ul a:hover {
	color: #171717;
}
.news-holder ul p {
	margin: 0 0 1px;
}
.news-holder ul time,
.news-holder ul em {
	font-size: 12px;
	font-weight: 700;
	color: #171717;
	display: block;
	font-style: normal;
}
.signup {
	overflow: hidden;
	padding: 24px 26px 23px 20px;
	background: #fed000;
	margin: 0 19px 42px 23px;
	font: 13px/15px "Open Sans", Arial, Helvetica, sans-serif;
}
.signup h2 {
	float: left;
	font: 15px/18px "dinbold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	margin: 10px 0 0;
}
.signup .form-area {
	float: right;
	width: 80%;
}
.signup .txt {
	float: left;
	margin: 10px 0 0;
	width: 53%;
}
.signup p {
	margin: 0 0 10px;
}
.signup .search-form {
	float: right;
	width: 309px;
}
.search-form input[type="email"] {
	width: 226px;
	padding: 5px 14px 4px;
	height: 37px;
	float: left;
	border: 0;
	background: #fff;
	font: 400 italic 13px/22px "Open Sans", Arial, Helvetica, sans-serif;
	outline: none;
}
.search-form button[type="submit"] {
	float: left;
	padding: 0 24px 0 11px;
	border: 0;
	background: #272727;
	color: #fff;
	text-transform: uppercase;
	font: 12px/37px "dinbold", Arial, Helvetica, sans-serif;
	cursor: pointer;
	position: relative;
	margin: 0;
	width: 78px;
}
.search-form button[type="submit"]:after {
	position: absolute;
	right: 11px;
	top: 12px;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 0 6px 7px;
	border-color: transparent transparent transparent #fff;
}
.search-form button[type="submit"]:hover {
	background: #999;
}
.partners {
	overflow: hidden;
	text-align: center;
	margin: 0 21px 4px 26px;
}
.partners h3 {
	margin: 0 0 39px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	font: 12px/17px "dinbold", Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	padding: 0 39px;
	color: #171717;
}
.partners h3:before,
.partners h3:after {
	background: #fed000;
	content: "";
	height: 1px;
	left: 100%;
	position: absolute;
	top: 7px;
	width: 9999px;
}
.partners h3:before {
	left: auto;
	right: 100%;
}
.partners ul {
	margin: 0 0 0 -9px !important;
	padding: 0;
	list-style: none;
	text-align: left;
}
.partners ul li {
	display: inline-block;
	vertical-align: top;
	margin: 0 11px 19px;
	width: 132px;
}
.partners ul li .text {
	display: table;
}
.partners ul li .img-holder {
	display: table-cell;
	height: 175px;
	vertical-align: middle;
}
.partners ul li a {
	display: inline-block;
	vertical-align: top;
}
.partners ul li a img {
	max-width: 132px;
}
.partners ul li a:hover img {
	opacity: 0.7;
}
#footer {
	max-width: 992px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	background: #f4f4f4;
	padding: 20px 30px 20px 29px;
	font: 12px/15px "Open Sans", Arial, Helvetica, sans-serif;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
#footer .copyright {
	float: left;
	color: #171717;
}
.copyright p {
	margin: 7px 0 0 0;
}
.copyright a {
	text-decoration: none;
	color: #171717;
}
.copyright a:hover {
	color: #999;
}
.copyright span a {
	color: #00a9f8;
}
.copyright span a:hover {
	color: #069;
}
#footer .powered {
	float: right;
	margin-top: 7px;
}
#footer .powered:hover {
	opacity: 0.7;
}
#footer .created {
	float: right;
	margin-right: 70px;
}
#footer .created:hover {
	opacity: 0.7;
}
.text-holder {
	overflow: hidden;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	margin: 0 70px 44px;
}
.text-holder.last {
	margin: 0 70px 47px;
}
.can-help .text-holder {
	margin: 0 70px 49px;
}
.event-page .text-holder {
	margin: 0 70px 4px;
}
.alert-page .text-holder {
	margin: 0 70px 33px;
}
.text-holder h1,
.text-holder h2 {
	margin: 0 0 23px;
	font-size: 26px !important;
	line-height: 28px;
	font-weight: 400;
	color: #ffc600;
	text-transform: uppercase;
}
.text-holder h2 {
	margin: 0 0 40px;
}
.text-holder p {
	margin: 0;
}
.form-holder {
	overflow: hidden;
	margin: 0 28px 26px 25px;
	background: #fffbe8;
	padding: 0 30px 0 16px;
}
.form-holder .align-right,
.form-holder .align-left {
	float: left;
	width: 55.7%;
	padding: 10px 0;
	border-right: 1px solid #efe9cd;
}
.form-holder .align-right {
	width: 42%;
	border: 0;
	float: right;
}
.align-right label,
.align-left label {
	color: #171717;
	font-size: 14px;
	line-height: 14px;
	text-transform: uppercase;
	width: 91px;
	display: inline-block;
	vertical-align: middle;
}
.align-right label {
	width: inherit;
	margin: 0 11px 0 0;
}
.align-left select {
	cursor: pointer;
	color: #7a7a7a;
	font-size: 15px;
	line-height: 18px;
}
.align-right button[type="submit"],
.align-left button[type="submit"] {
	display: inline-block;
	vertical-align: top;
	background: #ffc600;
	width: 28px;
	height: 28px;
	border-radius: 100%;
	text-align: center;
	color: #212121;
	text-transform: uppercase;
	border: 0;
	padding: 0;
	margin: 0 0 0 25px;
	font: 14px/28px "din_mediumregular", Arial, Helvetica, sans-serif;
}
.align-right button[type="submit"] {
	margin: 0 0 0 15px;
}
.align-right button[type="submit"]:hover,
.align-left button[type="submit"]:hover {
	background: #fc9;
}
.align-right input[type="text"] {
	width: 160px;
	height: 32px;
	border: 1px solid #efe9cd;
	background: #fff;
	font-size: 15px;
	line-height: 30px;
	padding: 0 10px;
	display: inline-block;
	vertical-align: middle;
}
.map-holder {
	overflow: hidden;
	margin: 0 28px 35px 30px;
	padding: 0 0 62px;
	border-bottom: 4px solid #fffbe8;
}
.map-holder h2 {
	margin: 0;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
}
.map-holder img {
	display: block;
	margin: 0 auto;
}
.info-holder {
	overflow: hidden;
	margin: 0 24px 33px 29px;
}
.relief-page .info-holder {
	margin: 0 24px 0 29px;
}
.info-holder .info {
	overflow: hidden;
	padding: 38px 116px 35px 47px;
	background: #fffbe8;
	margin: 0 0 21px;
}
.info-holder.yellow .info:nth-child(even) {
	background: #fffdf2;
}
.info-holder h3 {
	margin: 0 0 24px;
	color: #171717;
	font-size: 20px;
	line-height: 22px;
	font-weight: 400;
	text-transform: uppercase;
}
.info-holder .info-area {
	overflow: hidden;
}
.info-holder .info-text {
	float: left;
	width: 52%;
}
.info-holder .info-text p {
	margin: 0;
}
.info-holder .info-links {
	float: right;
	width: 35.8%;
	margin: 0;
	padding: 0;
	list-style: none;
}
.info-holder .info-links li {
	margin: 0 0 9px;
}
.info-holder .info-links li a:after {
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent transparent transparent #ffc600;
	border-width: 5px 0 5px 7px;
	content: "";
	margin: 0 0 0 6px;
}
.info-holder .info-links li a {
	display: inline-block;
	vertical-align: top;
	color: #2482d1;
	position: relative;
	padding: 0;
	text-decoration: none;
}
.info-holder .info-links li a:hover {
	color: #6cf;
}
.container {
	overflow: hidden;
	background: #fffbe8;
	margin: 0 23px 36px 22px;
	padding: 0 0 34px;
}
.container .nav {
	margin: 0;
	float: left;
	width: 21%;
}
.nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.nav ul li:first-child:after {
	background: none;
}
.nav ul li:after {
	position: absolute;
	left: 20px;
	right: 0;
	top: -3px;
	content: "";
	background: #ffc600;
	height: 1px;
}
.nav ul li {
	margin: 0 0 2px;
	position: relative;
	display: table;
	width: 100%;
}
.nav ul li a {
	text-decoration: none;
	color: #171717;
	text-transform: uppercase;
	font: 12px/17px Arial, Helvetica, sans-serif;
	display: table-cell;
	padding: 10px 0 10px 23px;
	position: relative;
	height: 57px;
	vertical-align: middle;
	word-spacing: -1px;
}
.nav ul li.active a:after,
.nav ul li a:hover:after {
	position: absolute;
	right: -12px;
	top: 0;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 29px 0 29px 12px;
	border-color: transparent transparent transparent #ffc600;
}
.nav ul li.active a,
.nav ul li a:hover {
	color: #fff;
	background: #ffc600;
	font-family: "dinbold", Arial, Helvetica, sans-serif;
}
.container .links-area {
	float: right;
	width: 71.7%;
	margin: 37px 25px 0 0;
}
.container h2 {
	margin: 0 0 18px;
	font-size: 20px;
	line-height: 22px;
	text-transform: uppercase;
}
.container p {
	margin: 0 0 35px;
}
.container .links-box {
	overflow: hidden;
}
.container .links {
	margin: 0;
	padding: 0 30px 0 0;
	list-style: none;
	width: 50%;
	float: left;
}
.container .links.last {
	float: right;
	width: 44%;
	padding: 0 30px 0 0;
}
.container .links li {
	margin: 0 0 9px;
}
.container .links li a {
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
	color: #2482d1;
	position: relative;
	padding: 0 20px 0 0;
}
.container .links li a:after {
	position: absolute;
	right: 0;
	top: 5px;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 0 6px 7px;
	border-color: transparent transparent transparent #ffc600;
}
.container .links li a:hover {
	color: #9cf;
}
.holder {
	overflow: hidden;
	text-align: center;
	padding: 0 0 16px;
	margin: 0 20px 20px;
	border-bottom: 1px solid #fed20a;
}
.holder.event-page {
	border: 0;
}
.alert-page .holder {
	margin: 0 20px 9px;
}
.holder .btn-holder {
	overflow: hidden;
	background: #fffbe8;
	padding: 18px 18px 16px;
	margin: 0 0 10px;
}
.alert-page .holder .btn-holder {
	padding: 13px 24px 16px;
}
.btn-holder .prev,
.btn-holder .back {
	text-decoration: none;
	float: left;
	color: #2482d1;
	position: relative;
	padding: 0 0 0 19px;
}
.btn-holder .back {
	float: right;
	padding: 0 17px 0 0;
	right: 9px;
}
.btn-holder .prev:hover,
.btn-holder .back:hover {
	color: #9cf;
}
.btn-holder .prev:after,
.btn-holder .back:after {
	position: absolute;
	left: 0;
	top: 6px;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 7px 6px 0;
	border-color: transparent #ffc600 transparent transparent;
}
.btn-holder .back:after {
	left: auto;
	right: 0;
	border-width: 6px 0 6px 7px;
	border-color: transparent transparent transparent #ffc600;
}
.btn-holder .title {
	display: inline-block;
	vertical-align: top;
	text-transform: uppercase;
	color: #171717;
	font-weight: normal;
}
.holder p {
	margin: 0;
}
.holder p a {
	text-decoration: none;
	color: #448cd1;
}
.holder p a:hover {
	color: #069;
}
.events-holder {
	overflow: hidden;
	margin: 0 26px 40px;
}
.alert-page .events-holder {
	margin: 0 26px 8px;
}
.events-holder .events {
	overflow: hidden;
	margin: 0 0 54px;
	padding: 0 0 0 20px;
}
.alert-page .events-holder .events {
	padding: 25px 0 34px 20px;
	margin: 0;
}
.alert-page .events:first-child ul li {
	margin: 0;
}
.alert-page .events.last ul li {
	margin: 0 0 35px;
}
.alert-page .events.last ul {
	margin: 0 0 -14px;
}
.alert-page .events-holder .events:first-child {
	padding-top: 0;
}
.events-holder.alert-page .events:first-child {
	border: 0;
}
.events-holder.alert-page .events {
	border-top: 1px solid #fed20a;
}
.events-holder h2:after {
	position: absolute;
	left: -19px;
	top: 3px;
	content: "";
	background: #fed20a;
	width: 6px;
	height: 20px;
}
.events-holder h2 {
	margin: 0 0 14px;
	color: #ffc600;
	font-size: 26px;
	line-height: 28px;
	font-weight: 400;
	text-transform: uppercase;
	position: relative;
}
.alert-page .events-holder h2 {
	margin: 0 0 18px;
}
.alert-page .events.last h2 {
	margin: 0 0 25px;
}
.events-holder a {
	text-decoration: none;
	color: #171717;
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding: 0 12px 0 0;
	margin: 0 0 2px;
}
.events-holder a:hover {
	color: #999;
}
.events-holder .more:after,
.events-holder > a:after {
	position: absolute;
	right: 0;
	top: 5px;
	width: 0;
	height: 0;
	content: "";
	border-style: solid;
	border-width: 6px 0 6px 7px;
	border-color: transparent transparent transparent #ffc600;
}
.events-holder .event-box {
	overflow: hidden;
}
.events-holder dl:first-child {
	width: 31%;
	float: left;
}
.events-holder dl {
	float: right;
	margin: 0;
	width: 67.3%;
}
.events-holder dl:first-child dt {
	width: 41px;
}
.events-holder dt {
	float: left;
	width: 90px;
	clear: left;
}
.events-holder dd {
	margin: 0;
	float: left;
}
.events-holder dd address {
	font-style: normal;
}
.btn-holder .years {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: left;
	text-transform: uppercase;
}
.btn-holder .years li {
	display: inline-block;
	vertical-align: top;
	margin: 0 7px 0 0;
}
.btn-holder .years li a {
	text-decoration: none;
	color: #2482d1;
}
.btn-holder .years li a:hover {
	color: #069;
}
.events-holder ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.events-holder ul li {
	margin: 0 0 30px;
}
.events-holder .title {
	display: block;
	color: #171717;
}
.events-holder ul li a {
	color: #7a7a7a;
}
.events-holder ul li p {
	margin: 0;
}
.events-holder ul li time {
	font-style: italic;
	color: #7a7a7a;
}
.events-holder .more {
	color: #2482d1;
}
/* custom select styles */
.align-left .jcf-select {
	display: inline-block;
	vertical-align: top;
	position: relative;
	border: 1px solid #efe9cd;
	background: #fff;
	margin: 0 3px 0 0;
	min-width: 160px;
	height: 32px;
	padding: 2px 0 0 5px;
}
.jcf-select select {
	z-index: 1;
	left: 0;
	top: 0;
}
.jcf-select .jcf-select-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
	font-size: 15px;
	line-height: 26px;
	margin: 0 35px 0 8px;
}
.jcf-select .jcf-select-opener {
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 7px 6px 0 6px;
	border-color: #ffc600 transparent transparent;
	right: 6px;
	top: 12px;
}
body > .jcf-select-drop {
	position: absolute;
	margin: -1px 0 0;
	z-index: 9999;
}
body > .jcf-select-drop.jcf-drop-flipped {
	margin: 1px 0 0;
}
.jcf-select .jcf-select-drop {
	position: absolute;
	margin-top: 0px;
	z-index: 9999;
	top: 100%;
	left: -1px;
	right: -1px;
}
.jcf-select .jcf-drop-flipped {
	bottom: 100%;
	top: auto;
}
.jcf-select-drop .jcf-select-drop-content {
	border: 1px solid #f00;
}
/* multiple select styles */
.jcf-list-box {
	overflow: hidden;
	display: inline-block;
	border: 1px solid #b8c3c9;
	min-width: 200px;
	margin: 0 15px;
}
/* select options styles */
.jcf-list {
	display: inline-block;
	vertical-align: top;
	position: relative;
	background: #fff;
	line-height: 14px;
	font-size: 12px;
	width: 100%;
}
.jcf-list .jcf-list-content {
	vertical-align: top;
	display: inline-block;
	overflow: auto;
	width: 100%;
}
.jcf-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.jcf-list ul li {
	overflow: hidden;
	display: block;
}
.jcf-list .jcf-overflow {
	overflow: auto;
}
.jcf-list .jcf-option {
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
	padding: 5px 9px;
	color: #656565;
	height: 1%;
}
.jcf-list .jcf-disabled {
	background: #fff !important;
	color: #aaa !important;
}
.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
	background: #e6e6e6;
	color: #000;
}
.jcf-list .jcf-optgroup-caption {
	white-space: nowrap;
	font-weight: bold;
	display: block;
	padding: 5px 9px;
	cursor: default;
	color: #000;
}
.jcf-list .jcf-optgroup .jcf-option {
	padding-left: 30px;
}
@media only screen and (max-width: 999px) {
	body {
		min-width: 320px;
	}
	#header {
		padding: 0 20px 13px 38px;
	}
	#header .links-holder {
		width: 81%;
	}
	#nav ul li {
		margin: 0 0 0 9px;
	}
	#nav ul li:after {
		margin: 0 0 0 -5px;
	}
	.can-help .text-holder {
		margin: 0 20px 49px;
	}
	.container {
		padding: 0 10px 34px 0;
		margin: 0 12px 36px 10px;
	}
	.nav ul li.active a:after,
	.nav ul li a:hover:after {
		top: 50%;
		margin: -28px 0 0 0;
	}
	.container .links.last {
		float: left;
		width: 50%;
	}
	.container .links li a {
		font-size: 13px;
	}
	.heading {
		margin: 0 20px 32px;
	}
	.column-holder {
		text-align: center;
		margin: 0 12px 25px;
	}
	.column-holder .column {
		margin: 0 10px 20px;
	}
	.column-holder .text {
		text-align: left;
	}
	.news-holder {
		text-align: center;
		margin: 0 10px 17px 14px;
	}
	.news-holder .news {
		text-align: left;
		margin: 0 10px 20px;
	}
	.signup {
		margin: 0 15px 42px;
		padding: 18px 14px 23px;
	}
	.signup h2 {
		float: none;
		text-align: center;
		margin: 0 0 5px;
	}
	.signup .form-area {
		float: none;
		width: 100%;
		text-align: center;
	}
	.signup .txt,
	.signup .search-form {
		float: none;
		display: inline-block;
		vertical-align: top;
	}
	.signup .txt {
		width: 100%;
	}
	.text-holder {
		margin: 0 20px 44px;
	}
	.form-holder {
		margin: 0 15px 26px;
		padding: 0 10px;
	}
	.form-holder .align-left {
		width: 59%;
	}
	.align-left label,
	.align-right label {
		width: 65px;
		font-size: 11px;
	}
	.align-left .jcf-select {
		min-width: 137px;
	}
	.align-right button[type="submit"],
	.align-left button[type="submit"] {
		margin: 0;
	}
	.form-holder .align-right {
		width: 39%;
	}
	.text-holder.last {
		margin: 0 20px 47px;
	}
	.events-holder dl:first-child,
	.events-holder dl {
		width: 50%;
	}
}
@media only screen and (max-width: 1060px) {
	#nav {
		margin-top: 0 !important;
	}
	#nav .js-slide-hidden {
		position: absolute !important;
	}
	.opener {
		float: right;
		width: 40px;
		height: 40px;
		background: #000;
		background: rgba(0, 0, 0, 0.5);
		position: relative;
		border-radius: 3px;
		display: block;
		margin: 0 48px 17px 0;
	}
	.opener:before,
	.opener:after,
	.opener span {
		background: #fff;
		border-radius: 4px;
		position: absolute;
		top: 50%;
		left: 25%;
		right: 25%;
		height: 4px;
		margin-top: -2px;
		transition: all 0.2s linear;
	}
	.opener:before,
	.opener:after {
		content: "";
		top: 30%;
	}
	.opener:after {
		top: 70%;
	}
	.active .opener span {
		opacity: 0;
	}
	.opener:hover {
		opacity: 0.9;
	}
	.active .opener:after,
	.active .opener:before {
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		top: 50%;
		left: 15%;
		right: 15%;
	}
	.active .opener:after {
		-moz-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	#header {
		padding: 0 3% 10px;
		position: relative;
		text-align: center;
	}
	.logo:after,
	.logo:before,
	.home .logo:after {
		display: none;
	}
	.logo {
		float: none;
		display: inline-block;
		vertical-align: top;
	}
	#header .links-holder {
		float: none;
		width: inherit;
	}
	.links-holder .lang {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	#nav {
		float: none;
		position: absolute;
		right: 0;
		top: 45px;
		width: 100%;
		z-index: 2;
	}
	#nav ul {
		overflow: hidden;
		width: 100%;
		padding: 5px 0;
		background: rgba(0, 0, 0, 0.6);
	}
	#nav ul li {
		float: none;
		width: 100%;
	}
	.links-holder .lang li:after,
	#nav ul li:after {
		display: none;
	}
	#nav ul li {
		margin: 0;
	}
	#nav ul li a {
		display: block;
		padding: 11px 10px 10px;
		color: #fff;
	}
	#nav ul li a:hover {
		background: #fed000;
		color: #000;
	}
	.container .nav {
		float: none;
		width: 100%;
		margin: 0 0 20px;
	}
	.container .links-area {
		margin: 0 0 15px;
		padding: 0 10px;
		float: none;
		width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.container .links,
	.container .links.last {
		width: 100%;
		float: none;
	}
	.partners h3 {
		padding: 0 19px;
	}
	.partners ul {
		margin: 0;
		text-align: center;
	}
	.partners ul li {
		margin: 0 11px 10px;
	}
	.partners {
		margin: 0 15px 4px;
	}
	#footer {
		text-align: center;
	}
	.copyright p {
		margin: 0 0 9px;
	}
	#footer .copyright,
	#footer .powered {
		float: none;
		width: 100%;
	}
	.btn-holder .title {
		margin: 0 40px;
	}
	.holder {
		margin: 0 10px 20px;
	}
	.event-page .text-holder {
		margin: 0 20px 4px;
	}
	.holder .btn-holder {
		padding: 18px 8px 16px;
	}
	.events-holder {
		margin: 0 10px 30px;
	}
	.events-holder .align-left,
	.events-holder .align-right {
		float: none;
		width: 100%;
		margin: 0 0 10px;
	}
	.events-holder .events {
		margin: 0 0 24px;
	}
	.visual img {
		display: block;
		width: 125%;
		height: auto;
	}
	.visual .caption:after {
		background: none;
	}
	.visual .caption {
		position: static;
		width: inherit;
		padding: 20px 15px 10px;
		border: 0;
	}
	.column-holder {
		margin: 0 0 25px;
	}
	.news-holder {
		margin: 0 0 17px;
	}
	.signup {
		margin: 0 0 42px;
		padding: 18px 8px 23px;
	}
	.signup p {
		margin: 0 0 10px;
	}
	.form-holder .align-right,
	.form-holder .align-left {
		float: none;
		width: 100%;
		border: 0;
	}
	.form-holder .align-left {
		border-bottom: 2px solid #efe9cd;
	}
	.align-left label,
	.align-right label {
		display: block;
		margin: 0 auto 5px;
	}
	.align-left .jcf-select,
	.align-right input[type="text"] {
		min-width: inherit;
		margin: 0 auto 10px;
		display: inline-block;
		vertical-align: top;
		width: 100%;
	}
	.align-right button[type="submit"],
	.align-left button[type="submit"] {
		display: block;
		margin: 0 auto;
	}
	.map-holder {
		margin: 0 15px 35px;
		padding: 0 0 10px;
	}
	.map-holder img {
		width: 100%;
		height: auto;
	}
	.text-holder h2 {
		margin: 0 0 20px;
	}
	.info-holder {
		margin: 0;
	}
	.info-holder .info {
		padding: 18px 15px 15px;
	}
	.info-holder h3 {
		font-size: 15px;
		margin: 0 0 10px;
	}
	.info-holder .info-text,
	.info-holder .info-links {
		float: none;
		width: 100%;
	}
	.info-holder .info-text {
		margin: 0 0 15px;
	}
	.alert-page .text-holder {
		margin: 0 15px 33px;
	}
	.alert-page .holder {
		margin: 0 0 9px;
	}
	.alert-page .events-holder {
		margin: 0 15px 8px;
	}
	.relief-page .info-holder {
		margin: 0 15px;
	}
	.events-holder dl:first-child,
	.events-holder dl {
		float: none;
		width: 100%;
		overflow: hidden;
		margin: 0 0 15px;
	}
}
@media only screen and (max-width: 479px) {
	#header {
		text-align: left;
	}
	.form-holder {
		margin: 0 0 26px;
	}
	.align-left label,
	.align-right label {
		width: inherit;
		text-align: center;
	}
}

/* Custom styles */
div#content {
	padding: 0 !important;
	margin: 0 24px 33px 24px !important;
	overflow: hidden;
}

.HighlightLight {
	background: #fffbe8;
}

div#m-header {
	overflow: hidden;
	text-align: center;
}

div#m-header a.return {
	float: left;
}

ul.page-tools {
	margin-bottom: 20px !important;
}

h2#ToolTitle,
h2.tool,
h2.tool-title,
div.content-detail h3.summary {
	margin: 5px 0 5px 0;
	font-size: 26px !important;
	line-height: 28px;
	font-weight: 400;
	color: #ffc600;
	text-transform: uppercase;
	clear: both !important;
	float: none !important;
}

div.tool-options {
	text-transform: uppercase;
}

div.tool-options.bottom {
	margin-bottom: 15px;
}

div.group {
	border-bottom: 1px solid #fed20a;
	padding-bottom: 27px;
}

div#content .group:last-child {
	border-bottom: none;
}

div.group {
	padding-left: 20px;
}

div.group h3 {
	border: none;
	margin: 0 0 5px 0;
	color: #ffc600;
	font-size: 26px;
	line-height: 28px;
	font-weight: 400;
	text-transform: uppercase;
	position: relative;
}

div.group h3:after {
	position: absolute;
	left: -19px;
	top: 3px;
	content: "";
	background: #fed20a;
	width: 6px;
	height: 20px;
}

div.group a.moreresults {
	font-weight: normal;
	font-size: 15px;
	margin-top: 20px;
}

div.content-summary {
	background: none !important;
	padding-left: 0 !important;
}

div.content-summary h4.summary a {
	color: #171717;
}

div.content-summary a.more {
	color: #171717;
}

form.main fieldset {
	background-color: #fffbe8;
}

div#AppMain.opportunities {
	overflow: hidden;
	background: #fffbe8;
	margin: 0;
	padding: 0;
}

div#AppMain.opportunities div#AppBody,
div#AppMain.opportunities div#PostDetail {
	float: right;
	width: 71.7%;
	margin: 37px 25px 0 0;
}

div#AppMain.opportunities div#PostNav {
	float: left;
	width: 21%;
}

div#AppMain.opportunities div#PostNav .rightcolumn {
	margin: 0;
	background-color: #fffbe8;
	padding: 0;
}

div#AppMain.opportunities div#PostNav .rightcolumn li {
	margin: 0 0 2px;
	position: relative;
	display: table;
	width: 100%;
}

div#AppMain.opportunities div#PostNav .rightcolumn li.current {
	font-weight: normal;
}

div#AppMain.opportunities div#PostNav .rightcolumn li a {
	text-decoration: none;
	color: #171717;
	text-transform: uppercase;
	font: 12px/17px "dinbold", Arial, Helvetica, sans-serif;
	display: table-cell;
	padding: 10px 0 10px 23px;
	position: relative;
	height: 57px;
	vertical-align: middle;
	word-spacing: -1px;
}

div#AppMain.opportunities div#PostNav .rightcolumn li:first-child:after {
	background: none;
}

div#AppMain.opportunities div#PostNav .rightcolumn li:after {
	position: absolute;
	left: 20px;
	right: 0;
	top: -3px;
	content: "";
	background: #ffc600;
	height: 1px;
}

div#AppMain.opportunities div#PostNav .rightcolumn li.active a:after,
div#AppMain.opportunities div#PostNav .rightcolumn li a:hover:after {
	position: absolute;
	right: -12px;
	top: 0;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 29px 0 29px 12px;
	border-color: transparent transparent transparent #ffc600;
}

div#AppMain.opportunities div#PostNav .rightcolumn li.active a,
div#AppMain.opportunities div#PostNav .rightcolumn li a:hover {
	color: #fff;
	background: #ffc600;
	font-family: "dinbold", Arial, Helvetica, sans-serif;
}

div#AppMain.opportunities div#PostDetail h3.summary {
	margin: 0 0 18px;
	font-size: 20px;
	line-height: 22px;
	text-transform: uppercase;
}

div#AppBody .links-area,
div#AppMain .links-area {
	float: right;
	margin: 0 0 20px 0;
}
div#AppBody h2,
div#AppMain h2 {
	margin: 0 0 18px;
	font-size: 20px;
	line-height: 22px;
	text-transform: uppercase;
}
div#AppBody p,
div#AppMain p {
	margin: 0 0 35px;
}
div#AppBody .links-box,
div#AppMain .links-box {
	overflow: hidden;
}
div#AppBody .links,
div#AppMain .links {
	margin: 0;
	padding: 0 30px 0 0;
	list-style: none;
	width: 50%;
	float: left;
}
div#AppBody .links.last,
div#AppMain .links.last {
	float: right;
	width: 44%;
	padding: 0 30px 0 0;
}
div#AppBody .links li,
div#AppMain .links li {
	margin: 0 0 9px;
}
div#AppBody .links li a,
div#AppMain .links li a {
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
	color: #2482d1;
	position: relative;
	padding: 0 20px 0 0;
}
div#AppBody .links li a:after,
div#AppMain .links li a:after {
	position: absolute;
	right: 0;
	top: 5px;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 0 6px 7px;
	border-color: transparent transparent transparent #ffc600;
}
div#AppBody .links li a:hover,
div#AppMain .links li a:hover {
	color: #9cf;
}
