form.primary {
	float: left;
	width: 100%;
}
div#content form fieldset {
	margin: 2em 0;
	clear: both;
}
form.primary fieldset legend {
	font-weight: bold;
	padding: 0 2em 0 0;
	font-size: 1.2em;
}

form.primary fieldset fieldset legend {
	font-size: 1em;
}

form.primary fieldset ul,
form.primary fieldset ol {
	list-style-type: none;
}

form.primary div.field {
	padding: 1em 0;
	border-bottom: 2px solid #fff;
	clear: both;
	float: left;
	width: 100%;
}

form.primary div.field .primary {
	width: 150px;
	float: left;
	text-align: right;
	padding: 0 1em;
}

form.primary div.field .offset {
	display: block;
}

form.primary div.field label.contained {
	display: block;
	line-height: 2em;
}

/* offset style */
form.primary div.controls {
	margin-top: 1em;
	margin-bottom: 1em;
	clear: both;
}

form.primary a.cancel {
	margin: 0 1em;
	font-size: 1.2em;
}

form.primary select,
form.primary input,
form.primary button,
form.primary textarea {
	padding: 0.2em;
}

form.primary option {
	padding: 0 0.5em;
}

.blacklisted {
	color: #333;
	text-decoration: line-through;
}

form.primary fieldset div label.required {
	font-weight: bold;
}

form.primary fieldset div label span.hint {
	display: block;
	font-weight: normal;
	font-size: 0.9em;
}

form.primary input.radio,
form.primary input.checkbox {
	margin-right: 0.5em;
}

textarea {
	font-family: inherit;
}

/* new form styles */

form.main {
	clear: both;
	float: left;
	width: 100%;
}
form.main fieldset li {
	clear: both;
	border-bottom: 2px solid #fff;
	float: left;
	width: 100%;
	list-style-type: none;
}

form.main fieldset p {
	margin: 1em;
}
form#frm_natlSearch li {
	text-align: center;
}

form.main fieldset legend {
	font-weight: bold;
	padding: 0 2em 0 0;
	font-size: 1.2em;
}

form.main fieldset li label {
	float: left;
	text-align: right;
	width: 30%;
	padding: 0.5em 0;
}

form.main fieldset li div.hint {
	font-size: 0.9em;
	font-style: italic;
}

form.main fieldset li div.offset {
	display: inline;
}

form.main fieldset li input.text,
form.main li input.password,
form.main li textarea,
form.main li .offset,
form.main li select,
form.main li input.file {
	width: 55%;
	margin: 0.2em 0.2em 0.2em 3%;
	/*margin-left: 3%;*/
	padding: 0.2em;
}
form.main fieldset li iframe {
	margin-left: 3% !important;
}
form.main div.offset input {
	margin: 0;
}
form.main fieldset li input.short,
form.main li select.short {
	width: 25%;
}
form.main fieldset li label.inline,
form.main li div.hint {
	margin-left: 33%;
	text-align: left;
	width: 50%;
}
form.main fieldset li label.inline input {
	margin-right: 0.5em;
}
form.main div.controls {
	border-top: 0;
	text-align: left;
	margin-left: 33%;
	margin-bottom: 1em;
}
form.main div.controls a {
	margin-right: 1em;
}
form.main li span.time {
	margin-left: 3%;
	width: 2em;
}
form.main li span.time select {
	width: 5em;
}
form.main fieldset li div.offset {
	display: block;
	margin-left: 33%;
	width: auto;
}
form.main fieldset li div.offset ul {
	list-style-type: none;
	margin-top: 0;
}
form.main fieldset li div.offset li {
	margin-top: 0;
	clear: inherit;
	border: none;
	width: auto;
	float: inherit;
}
form.main fieldset li div.offset li label {
	float: inherit;
}
form.main fieldset div.verify {
	padding: 0;
	margin-bottom: 0.5em;
}

form.main fieldset div.verify input {
	width: 3em;
	height: 30px;
	font-size: 25px;
	padding: 3px;
	vertical-align: middle;
}
form.main fieldset div.verify img {
	vertical-align: middle;
}
form.main .hidden {
	display: none;
}
/* ckeditor styles */
form.main fieldset li .cke_skin_kama {
	float: left !important;
	margin-left: 3%;
}
form.main fieldset li .cke_skin_kama iframe {
	margin-left: 0 !important;
}
form.main fieldset li .cke_skin_kama textarea {
	margin-left: 0 !important;
}
#multiple-file-list li {
	position: relative;
}

#multiple-file-list .input-placeholder-text {
	color: grey !important;
	top: 0.5em;
	left: 33.7%;
}

#addResourceBtn {
	margin-top: 1em;
	margin-left: 33.3%;
}

input[readOnly="readOnly"] {
	background-color: #ccc;
}

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
